import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';

@Component({
  selector: 'speed-slider',
  templateUrl: './speed-slider.component.html',
  styleUrls: ['./speed-slider.component.scss']
})
export class SpeedSlider implements OnInit {
  raf = null;
  mdown = false;
  mPos = { x: 0, y: 0 }
  elementPosition = { x: 0, y: 0 }
  radius = 36;
  knobRadius = 10;
  maxDiff = 40;
  constraint = 360;
  target = 0;
  centerX = 0;
  centerY = 0;
  ctx;
  circleOffset;
  x = 0;
  y = 0;
  $circle;
  $innerCircle;
  $knob;
  $progress;
  canvasSize;
  title = "";
  compInited = false;
  @ViewChild('circle', { static: true }) public circle: any;
  @ViewChild('innercircle', { static: true }) public innerCircle: any;
  @ViewChild('knob', { static: true }) public knob: any;
  @ViewChild('progress', { static: true }) public progress: any;
  //slidercont
  @ViewChild('slidercont', { static: true }) public slidercont: any;
  //
  @Output() rsOutput: EventEmitter<any> = new EventEmitter<any>();
  //
  @Input() default;
  constructor() {

  }
  @Input() set initcomp(v: any) {
    if (v) {
      this.initSlider();
      this.compInited = true;
    }
  }
  id
  @Input() set name(v: any) {
    if (v) {
      this.title = v;
      this.id = v.split('')[0];
    }
  }
  initSlider() {
    this.$circle = this.circle.nativeElement;
    this.$innerCircle = this.innerCircle.nativeElement;
    this.$knob = this.knob.nativeElement;
    this.$progress = this.progress.nativeElement;
    this.ctx = this.$progress.getContext('2d');
    let rect = this.$circle.getBoundingClientRect()
    this.circleOffset = {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    };
    this.elementPosition = {
      x: this.circleOffset.left,
      y: this.circleOffset.top
    }
    let prect = this.$progress.getBoundingClientRect()
    this.centerX = prect.width / 2;
    this.centerY = prect.height / 2;
    this.canvasSize = prect.width;
    this.$progress.width = this.canvasSize;
    this.$progress.height = this.canvasSize;
    let bg = this.getBackground();
    let c = this.slidercont.nativeElement;
    c.style.backgroundImage = "url(" + bg + ")";
    //c.style.backgroundRepeat="no-repeat";
    //c.style.backgroundPosition="center";
    this.$innerCircle.style.backgroundImage = "url(" + bg + ")";
    //this.$innerCircle.style.backgroundRepeat="no-repeat";
    if (!this.compInited) {
      this.target = (this.default) * 360 / 200;
      this.roundTarg = this.default;
    }
    this.updateSlider();
  }
  ngAfterViewInit() {
    console.log("initview speed slider");
    const para = document.getElementById('knob' + this.id);
    console.log('Pointer down event', para);
    // para.addEventListener('pointerdown', (event) => {
    //   console.log('Pointer down event');
    // });
    para.addEventListener("touchstart", (event) => {
      console.log("Here a touchstart event is triggered");
      this.mdown = true;
    });
    para.addEventListener("touchmove", (event) => {
      console.log("Here a touchmove event is triggered");
      if (this.mdown) {
        this.setMousePosition(event);
      }
    });
    para.addEventListener("touchend", (event) => {
      console.log("Here a touchend event is triggered");
      this.mdown = false;
    });
  }
  ngOnInit() {
    console.log("init speed slider")
  }
  // Callbacks
  @HostListener('mousedown', ['$event'])
  onMouseDown(event) {
    if (event.target.classList.contains('knob'))
      this.mdown = true;
  }
  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event) {
    this.mdown = false;
  }
  @HostListener('mousemove', ['$event'])
  onMouseMove(event) {
    if (this.mdown) {
      this.setMousePosition(event);
    }
  }
  roundTarg = 0;
  setMousePosition(event) {
    var pagex = event.pageX ? event.pageX : event.changedTouches[0]["pageX"];
    var pagey = event.pageY ? event.pageY : event.changedTouches[0]["pageY"];
    this.mPos = {
      x: pagex - this.elementPosition.x,
      y: pagey - this.elementPosition.y
    };


    let atan = Math.atan2(this.mPos.x - this.radius, this.mPos.y - this.radius);
    let target = -atan / (Math.PI / 180) + 180;

    let diff = Math.abs(target - this.target);

    if (diff < this.maxDiff && target <= this.constraint) {
      this.target = (target);
      this.roundTarg = Math.round(target * 200 / 360);
      this.rsOutput.emit(this.roundTarg);
    }
  }

  getBackground() {
    let steps = 60;
    let dividerEvery = 15;
    this.$progress.height = this.canvasSize;
    this.$progress.width = this.canvasSize;
    this.ctx.save();
    this.ctx.translate(this.centerX, this.centerY);
    for (let i = 0, end = steps; i <= end; i++) {
      this.ctx.beginPath();
      this.ctx.rotate(Math.PI * 2 / steps);
      if (i % dividerEvery === dividerEvery - 1) {
        this.ctx.lineWidth = 2;
        this.ctx.moveTo(50, 0);
        this.ctx.lineTo(26, 0);
        this.ctx.strokeStyle = "#ffff00";
      } else {
        this.ctx.lineWidth = 1;
        this.ctx.lineTo(45, 0);
        this.ctx.lineTo(25, 0);
        this.ctx.strokeStyle = "#888833";
      }
      this.ctx.stroke();
    }
    this.ctx.restore();
    let img = this.$progress.toDataURL();
    this.ctx.clearRect(0, 0, this.canvasSize, this.canvasSize);
    return img;
  }

  drawArc() {
    let center = { x: this.centerX, y: this.centerY };
    let radius = this.radius;
    let quadrants = [
      {
        "angleStart": Math.PI * -0.5,
        "angleEnd": 0,
        "x1": center.x,
        "y1": center.y - radius,
        "x2": center.x + radius,
        "y2": center.y,
        "colorStops": [
          { "stop": 0, "color": "#00ff00" },
          { "stop": 1, "color": "#00ff00" }
        ]
      },
      {
        "angleStart": 0,
        "angleEnd": Math.PI * 0.5,
        "x1": center.x + radius,
        "y1": center.y,
        "x2": center.x,
        "y2": center.y + radius,
        "colorStops": [
          { "stop": 0, "color": "#00ff00" },
          { "stop": 1, "color": "yellow" }
        ]
      },
      {
        "angleStart": Math.PI * 0.5,
        "angleEnd": Math.PI,
        "x1": center.x,
        "y1": center.y + radius,
        "x2": center.x - radius,
        "y2": center.y,
        "colorStops": [
          { "stop": 0, "color": "yellow" },
          { "stop": 1, "color": "orange" }
        ]
      },
      {
        "angleStart": Math.PI,
        "angleEnd": Math.PI * 1.5,
        "x1": center.x - radius,
        "y1": center.y,
        "x2": center.x,
        "y2": center.y - radius,
        "colorStops": [
          { "stop": 0, "color": "orange" },
          { "stop": 1, "color": "red" }
        ]
      }
    ];
    this.$progress.height = this.canvasSize;
    this.$progress.width = this.canvasSize;
    let ctx = this.ctx;
    let qlen = Math.floor(this.target / 90);
    for (let i = 0; i < qlen; ++i) {
      let quad = quadrants[i];
      let grad = ctx.createLinearGradient(quad.x1, quad.y1, quad.x2, quad.y2);
      // Color stops.
      for (let j = 0; j < quad.colorStops.length; ++j) {
        var cs = quad.colorStops[j];
        grad.addColorStop(cs.stop, cs.color);
      }
      // Draw arc.
      ctx.beginPath();
      ctx.arc(center.x, center.y, radius, quad.angleStart, quad.angleEnd);
      ctx.strokeStyle = grad;
      ctx.lineWidth = 8;
      ctx.stroke();
    }
    let quad = quadrants[qlen];
    if (quad) {
      let grad = ctx.createLinearGradient(quad.x1, quad.y1, quad.x2, quad.y2);
      // Color stops.
      for (let j = 0; j < quad.colorStops.length; ++j) {
        var cs = quad.colorStops[j];
        grad.addColorStop(cs.stop, cs.color);
      }
      // Draw arc.
      ctx.beginPath();
      ctx.arc(center.x, center.y, radius, quad.angleStart, (this.target + (-90)) * Math.PI / 180);
      ctx.strokeStyle = grad;
      ctx.lineWidth = 8;
      ctx.stroke();
    }

  }
  xdrawArc() {
    this.$progress.width = this.canvasSize;
    this.$progress.height = this.canvasSize;
    this.ctx.save();

    this.ctx.translate(this.centerX, this.centerY - this.radius);
    this.ctx.rotate(-90 * Math.PI / 180);
    this.ctx.strokeStyle = "#0083ad";

    this.ctx.beginPath();
    this.ctx.lineWidth = 8;
    this.ctx.arc(0 - this.radius + 1, -1, this.radius - 1, 0, this.target * Math.PI / 180, false);

    this.ctx.stroke();
    this.ctx.restore();
  }
  setPosition() {
    this.x = Math.round(this.radius * Math.sin(this.target * Math.PI / 180)) + this.radius - this.knobRadius + 4;
    this.y = Math.round(this.radius * -Math.cos(this.target * Math.PI / 180)) + this.radius - this.knobRadius + 4;
    this.$knob.style.left = this.x + "px";
    this.$knob.style.top = this.y + "px";
  }
  updateSlider() {
    let anim = () => {
      this.setPosition();
      this.drawArc();
      this.raf = requestAnimationFrame(anim);
    }
    this.raf = requestAnimationFrame(anim);
    this.setPosition();
    this.drawArc();
  }
}
