import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'appinput',
    templateUrl: './appinput.component.html',
    styleUrls: ['./appinput.component.styl']
})
export class AppinputComponent implements OnInit {
    @Input() parentdata;
    @Input('data') modifieddata;
    X1: string;
    Y1: string;
    X2: string;
    Y2: string;

    @Output() passdatax1:EventEmitter<string> = new EventEmitter<string>();
    @Output() passdatay1:EventEmitter<string> = new EventEmitter<string>();
    @Output() passdatax2:EventEmitter<string> = new EventEmitter<string>();
    @Output() passdatay2:EventEmitter<string> = new EventEmitter<string>();


    ngOnInit() {

    }

    onClick() {
        this.passdatax1.emit(this.X1);
        this.passdatay1.emit(this.Y1);
        this.passdatax2.emit(this.X2);
        this.passdatay2.emit(this.Y2);
    }

}
