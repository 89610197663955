import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, DoCheck, HostListener, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';

import { MatIconRegistry } from "@angular/material/icon";
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { DomSanitizer } from '@angular/platform-browser';
import { CommunicationService } from '../services/communication.service';

export interface Element {
  name: string;
  icon: any;
  state: boolean;
  ticon?
}

const ELEMENT_DATA: Element[] = [

  { name: 'play anim', icon: 'play_arrow', state: false, ticon: 'pause' },
  { name: 'heat map', icon: 'layers', state: true, ticon: 'layers_clear' },
  { name: 'home', icon: 'home', state: true },
  { name: '2D', icon: false, state: true },
  { name: 'zoomin', icon: 'add', state: true },
  { name: 'zoomout', icon: 'remove', state: true },
  { name: 'left', icon: 'left', state: false },
  { name: 'right', icon: 'right', state: false },
  { name: 'back', icon: 'back', state: false },
  { name: 'wireframe', icon: 'left', state: false },
  { name: 'solid', icon: 'right', state: false },
  { name: 'texture', icon: 'back', state: false },
  { name: 'camera', icon: 'video_camera_on', state: true, ticon: 'video_camera_off' },
  { name: 'top', icon: 'top', state: false },
  { name: 'label', icon: 'label', state: false },
  { name: 'sensor', icon: 'wifi', state: true },
  { name: 'target', icon: 'gps', state: true },
  { name: 'drag', icon: 'anchor', state: false },
  { name: 'drag_reset', icon: 'water', state: true },
  { name: 'front', icon: 'front', state: false },
  { name: 'explode', icon: 'device_hub', state: false },
  { name: 'water', icon: 'water', state: true },
  { name: 'marker', icon: 'marker', state: false },
  { name: 'follow', icon: 'visibility', state: false }
];



@Component({
  selector: 'app-positiontools',
  templateUrl: './positiontools.component.html',
  styleUrls: ['./positiontools.component.scss']
})
export class PositiontoolsComponent implements AfterViewInit, DoCheck, OnInit {
  // @ViewChild (MatSelectionList ,{ static: true }) obj: MatSelectionList;
  @ViewChild('menuTrigger') sliderMenu: MatMenuTrigger;
  displayedColumns: string[] = ['position'];
  canvasobjects = [];
  dataSource = ELEMENT_DATA;
  version = 'Ver 1.0';
  listbuttonsclick = false;
  panelstatus = false;
  animStatus: string;
  disableTransCtrl = false;
  animationState = false;
  hideOceanState = false;
  animationMode="0"
  @Output() positionToolEvent = new EventEmitter<any>();
  @Output() changeCameraMode = new EventEmitter<string>();
  @Output() getElementProperties = new EventEmitter<string>();
  @Output() changeObjectPosition = new EventEmitter<object>();
  @Output() changeObjectSize = new EventEmitter<object>();
  @Output() changeObjectOrientation = new EventEmitter<object>();
  @Output() zoom = new EventEmitter<string>();
  @Output() trigger = new EventEmitter<object>();
  @Output() cameraHideShow = new EventEmitter<any>();
  @Output() sensorHideShow = new EventEmitter<any>();
  @Output() targetHideShow = new EventEmitter<any>();
  @Output() multiTagState = new EventEmitter<any>();
  @Input() getElements;
  @Input() selElement;
  @Input() positiontool;
  @Input() socketcontroler;
  @Input() showAnim;
  @Input() testMode: any = '';
  engineAnimationStatus: any = false;
  idgEngineAnimationStatus = false;
  dragEninge: any = false;
  @Input() set anim_status(v: string) {
    this.animStatus = v;
    if (v === 'stopped') {
      this.dataSource[0].state = true;
    } else if (v === 'paused') {
      this.dataSource[0].state = true;
    } else if (v === 'playing') {
      this.dataSource[0].state = !true;
    }
  }
  @Input() set _disableTrans(v: any) {
    this.disableTransCtrl = v;
  }
  @Input() set shipDocked(v: any) {
    this.dataSource[17].state = v;
  }
  @Input() focusMode: boolean = false;
  @Input() activeModel: any = "idg";

  private speedDialFabButtons = [
    { name: 'play anim', icon: 'play_arrow', },
    { name: 'heat map', icon: 'layers', state: true, ticon: 'layers_clear' },
    { name: 'home', icon: 'home', state: true },
    { name: '2D', icon: false, state: true },
    { name: 'zoomin', icon: 'add', state: true },
    { name: 'zoomout', icon: 'remove', state: true },
    { name: 'left', icon: 'left', state: false },
    { name: 'right', icon: 'right', state: false },
    { name: 'back', icon: 'back', state: false },
    { name: 'wireframe', icon: 'left', state: false },
    { name: 'solid', icon: 'right', state: false },
    { name: 'texture', icon: 'back', state: false },
    { name: 'camera', icon: 'video_camera_on', state: true, ticon: 'video_camera_off' },
    { name: 'top', icon: 'top', state: false },
    { name: 'label', icon: 'label', state: false },
    { name: 'sensor', icon: 'wifi', state: true },
    { name: 'target', icon: 'gps', state: true }
  ];
  windowWidth: any = window.innerWidth;
  windowHeight: any = window.innerHeight;
  mobileControlTool: any = false;
  controlToggleOpen: any = false;
  camView = '#4';
  searchTerm = '';
  changeView = {
    '#4': '#1',
    '#1': '#2',
    '#2': '#3',
    '#3': '#4'
  };
  constructor(private communicationServ: CommunicationService, private cdr: ChangeDetectorRef, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      `2d-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/2d-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `3d-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/3d-icon.svg")
    );
    console.log("this.dataSource", this.dataSource);
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    if (this.windowWidth < 760) {
      this.mobileControlTool = true;
    }
  }
  showControlTool() {
    this.controlToggleOpen = !this.controlToggleOpen;
    this.mobileControlTool = !this.mobileControlTool;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    this.windowHeight = event.target.innerHeight;
    if (this.windowWidth < 760) {
      this.mobileControlTool = true;
    } else {
      this.mobileControlTool = false;
    }
  }
  ngAfterViewInit(): void {
    this.panelstatus = true;
    this.cdr.detectChanges();
  }
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  broadcastSubs: any;
  ngOnInit() {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "main") {
          if (data.event === 'quizMode') {
            this.buttonclick(this.dataSource[17], 17)
          }
        }
        if (data.src === "sidepanel") {
          if (data.event == 'explode') {
            this.buttonclick(this.dataSource[20], 20);
          }
          if (data.event === 'disabled-controltools') {
            this.buttonclick(this.dataSource[18], 18);
            setTimeout(() => {
              this.animationState = data['data'];
              this.animationMode=data["key"]||"0"
            }, 1800);
          }
          
          if (data.event == "dragReset") {
            this.engineAnimationStatus = data.key;
           
            if(this.engineAnimationStatus){
              this.dataSource[20].state = false;
              this.positionToolEvent.next('drag');
              this.positionToolEvent.next('drag_reset');
              this.dataSource.forEach(ele => {
                if (ele.name == "drag") {
                  ele.state = false
                }
              })
            }          
          } 
          if (data.event == "idg-dragReset") {
            // this.idgEngineAnimationStatus = data.key;   
            this.engineAnimationStatus = data.key;        
            if(this.engineAnimationStatus){
              this.dataSource[20].state = false;
              this.positionToolEvent.next('drag');
              this.positionToolEvent.next('drag_reset');
              this.dataSource.forEach(ele => {
                if (ele.name == "drag") {
                  ele.state = false
                }
              })
            }           
          } 
          if (data.event == "startAnimation") {
            // this.idgEngineAnimationStatus = data.key;
            if (data.startAnim) {
              this.dataSource.forEach(ele => {
                if (ele.name == "drag") {
                  ele.state = false
                }
              })
              this.dataSource[20].state = false;
              this.positionToolEvent.next('drag');
              this.positionToolEvent.next('drag_reset');
            }
          }
          if (data.event === "showhideocean") {
            let boo = data['data'];
            this.hideOceanState = boo;
          }
        }
      })
  }
  searchmode = false;
  ngDoCheck(): void {
    // fetch elements
    if (!this.searchmode) {
      this.canvasobjects = this.getElements;
    }
  }

  closetool() {
    this.positiontool = false;
  }

  closecontant() {
    this.panelstatus = true;
    this.socketcontroler = false;
  }

  opencontant(callback) {
    this.panelstatus = false;
    if (!this.socketcontroler) {
      this.socketcontroler = true;
    }
    callback(this.socketcontroler);
  }
  viewMode: any = 'texture';
  buttonclick(row, index) {
    if (row.name === 'changecamera') {
      this.camView = this.changeView[this.camView];
      this.positionToolEvent.next(this.camView);
    } else if (row.name === 'drag') {
      this.dataSource[index].state = !this.dataSource[index].state;
      if (this.engineAnimationStatus) {
        this.dragEninge = this.dataSource[index].state
        this.broadcastInfo({ src: "positionTool", event: "drag", key: this.engineAnimationStatus, "dragView": this.dragEninge });
      }
      this.positionToolEvent.next(row.name);

    } else if (row.name === 'drag_reset') {
      if (this.dataSource[20].state) {
        if (!this.focusMode) {
          this.explode_val = 1;
          this.dataSource[20].state = false;
          this.sliderMenu.closeMenu();
          this.broadcastInfo({ src: 'controls', event: 'explode_off', data: null, key: null });
        }
      }
      this.dataSource[index].state = !this.dataSource[index].state;
      this.positionToolEvent.next(row.name);
    } else if (row.name === 'home') {
      this.positionToolEvent.next(row.name);
    } else if (row.name === '2D') {
      this.changeCameraMode.next(row.name);
      this.dataSource[index].name = '3D';
    } else if (row.name === '3D') {
      this.changeCameraMode.next(row.name);
      this.dataSource[index].name = '2D';
    } else if (row.name === 'zoomin') {
      this.zoom.next(']');
    } else if (row.name === 'zoomout') {
      this.zoom.next('[');
    } else if (row.name === 'xwireframe') {
      this.changeWireframeMode(index);
    } else if (row.name === 'heat map') {
      this.dataSource[index].state = !this.dataSource[index].state;
      this.positionToolEvent.next('heat_mode');
    } else if (row.name === 'play anim') {
      this.dataSource[index].state = !this.dataSource[index].state;
      this.positionToolEvent.next('play_anim');
    } else if (row.name === 'left') {
      this.positionToolEvent.next('left');
    } else if (row.name === 'right') {
      this.positionToolEvent.next('right');
    } else if (row.name === 'back') {
      this.positionToolEvent.next('back');
    } else if (row.name === 'top') {
      this.positionToolEvent.next('top');
    } else if (row.name === 'front') {
      this.positionToolEvent.next('front');
    } else if (row.name === 'solid') {
      let idx = this.dataSource.findIndex(e => e.name == 'label');
      if (idx > -1) {
        this.dataSource[idx].state = false;
      }
      let index = this.dataSource.findIndex(e => e.name == 'sensor');
      if (index > -1) {
        this.dataSource[index].state = true;
      }
      let cameraIdx = this.dataSource.findIndex(e => e.name == 'camera');
      if (cameraIdx > -1) {
        this.dataSource[cameraIdx].state = true;
      }
      let targIdx = this.dataSource.findIndex(e => e.name == 'target');
      if (targIdx > -1) {
        this.dataSource[targIdx].state = true;
      }
      this.positionToolEvent.next('solid');
      this.viewMode = 'solid';
    } else if (row.name === 'texture') {
      let idx = this.dataSource.findIndex(e => e.name == 'label');
      if (idx > -1) {
        this.dataSource[idx].state = false;
      }
      let index = this.dataSource.findIndex(e => e.name == 'sensor');
      if (index > -1) {
        this.dataSource[index].state = true;
      }
      let cameraIdx = this.dataSource.findIndex(e => e.name == 'camera');
      if (cameraIdx > -1) {
        this.dataSource[cameraIdx].state = true;
      }
      let targIdx = this.dataSource.findIndex(e => e.name == 'target');
      if (targIdx > -1) {
        this.dataSource[targIdx].state = true;
      }
      this.positionToolEvent.next('texture');
      this.viewMode = 'texture';
    } else if (row.name === 'wireframe') {
      let idx = this.dataSource.findIndex(e => e.name == 'label');
      if (idx > -1) {
        this.dataSource[idx].state = false;
      }
      let index = this.dataSource.findIndex(e => e.name == 'sensor');
      if (index > -1) {
        this.dataSource[index].state = true;
      }
      let cameraIdx = this.dataSource.findIndex(e => e.name == 'camera');
      if (cameraIdx > -1) {
        this.dataSource[cameraIdx].state = true;
      }
      let targIdx = this.dataSource.findIndex(e => e.name == 'target');
      if (targIdx > -1) {
        this.dataSource[targIdx].state = true;
      }
      this.positionToolEvent.next('wireframe');
      this.viewMode = 'wireframe';
    } else if (row.name == 'camera') {
      this.dataSource[index].state = !this.dataSource[index].state;
      this.cameraHideShow.next(this.dataSource[index].state);
    } else if (row.name == 'sensor') {
      this.dataSource[index].state = !this.dataSource[index].state;
      this.sensorHideShow.next(this.dataSource[index].state);

    } else if (row.name == 'label') {
      //if(this.viewMode == 'solid')
      this.dataSource[index].state = !this.dataSource[index].state;
      this.multiTagState.next({ state: this.dataSource[index].state });
    } else if (row.name == 'target') {
      this.dataSource[index].state = !this.dataSource[index].state;

      this.targetHideShow.next(this.dataSource[index].state);

    } else if (row.name == 'explode') {
      this.dataSource[index].state = !this.dataSource[index].state;
      //this.positionToolEvent.next(row.name);
      let v = this.dataSource[index].state ? this.explode_val : 0;
      if (!this.dataSource[index].state) {
        this.explode_val = 1;
      } else {

      }
      this.broadcastInfo({ src: 'controls', event: 'explode', data: v, key: this.dataSource[index].state });
      // this.disableTransCtrl=true;
      if (this.dataSource[17].state) {
        this.buttonclick(this.dataSource[17], 17);
        // this.dataSource[17].state=false;     
      }
    } else if (row.name === 'water') {
      this.dataSource[index].state = !this.dataSource[index].state;
      this.broadcastInfo({ src: 'sidepanel', event: 'toggleocean', data: !this.dataSource[index].state, key: "" });
    } else if (row.name === 'marker') {
      this.dataSource[index].state = !this.dataSource[index].state;
      this.broadcastInfo({ src: 'sidepanel', event: 'draw-mode', data: !this.dataSource[index].state, key: "" });
    } else if (row.name === 'follow') {
      this.dataSource[index].state = !this.dataSource[index].state;
      this.broadcastInfo({ src: 'sidepanel', event: 'rhib_follow', data: this.dataSource[index].state, key: "" });
    }
  }

  _wireframemode = false;
  changeWireframeMode(index) {
    if (!this._wireframemode) {
      // this.dataSource[index].icon = 'signal_cellular_null';
      this._wireframemode = true;
    } else if (this._wireframemode) {
      // this.dataSource[index].icon = 'signal_cellular_4_bar';
      this._wireframemode = false;
    }
  }

  getWireFrameStatus() {
    return this._wireframemode;
  }


  searchElements(searchElem) {
    this.searchmode = true;
    this.canvasobjects = [];
    this.canvasobjects = this.getElements.filter(function (tag) {
      return tag.indexOf(searchElem) >= 0;
    });
    console.log("canvasobjects.length :: ", this.canvasobjects.length);
  }

  activeList;
  showObject(obj_name, index) {
    if (this.activeList != index) {
      this.activeList = index;
      this.getElementProperties.next(obj_name);
    } else {
      this.activeList = null;
      this.getElementProperties.next(obj_name);
    }
  }


  locateShape(shapeCoords): any {
    const newLocation = (shapeCoords as String).split(',', 3);
    const x = Number(newLocation[0].split(':', 2)[1]); // scaleFactor * Math.sin(2 * percnt) / 2 * 0.005 + cx;
    const y = Number(newLocation[1].split(':', 2)[1]); // sscaleFactor * Math.cos(percnt) * 0.004 + cz;
    const z = Number(newLocation[2].split(':', 2)[1]); // sMath.sin(percnt) * 40 + 80;  // altitude
    const geopos = { 'position': { 'x': x, 'y': y, 'z': z } };
    this.changeObjectPosition.next(geopos);
  }


  changeShapeSize(shapeSize): any {
    const newSize = Number(shapeSize);
    const geoSize = { 'size': newSize };
    this.changeObjectSize.next(geoSize);
  }

  changeShapeOrientation(shapeOrientation): any {
    console.log("shapeOrientation : ", shapeOrientation);
    const newSize = Number(shapeOrientation);
    const geoSize = { 'orientation': newSize };
    this.changeObjectOrientation.next(geoSize);
  }
  triggerkey(key) {
    this.trigger.next(key);
  }
  explode_val = 1
  sliderChange(event) {
    this.explode_val = event.value
    this.broadcastInfo({ src: 'controls', event: 'explode', data: event.value, key: "" });
  }

}
