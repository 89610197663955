import { Injectable } from '@angular/core';
import * as Rx from 'rxjs';
import { ConfigService } from '../services/config.service';
import * as io from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})

export class CommunicationService {
  private instance: Rx.Subject<any>;
  private IframeInstance: Rx.Subject<any>;
  private iframeEvents: any = {};

  constructor(private configServ:ConfigService) {
    window.addEventListener("message", (event) => {
      let obj = event['data'];
      if (obj) {
        let srcName = obj['iframeConnection'];
        if (!srcName) return;
        this.iframeEvents[srcName] = event;
        this.getIframeInstance().next(event['data']);
      }
    }, false);
  }

  createIframeInstance(data) {
    if (this.iframeEvents[data['iframeConnection']]) {
      let event = this.iframeEvents[data['iframeConnection']];
      event.source.postMessage(data, event.origin);
    } else {
      parent.postMessage(data, "*");
    }
  }

  public getIframeInstance(): Rx.Subject<any> {
    if (!this.IframeInstance) {
      this.IframeInstance = new Rx.Subject<any>();
    }
    return this.IframeInstance;
  }

  createInstance(): Rx.Subject<any> {
    const instance = new Rx.Subject<any>();
    return instance;
  }

  public getInstance(): Rx.Subject<any> {
    if (!this.instance) {
      this.instance = this.createInstance();
    }
    return this.instance;
  }
  /* * * * *
  * create/init socket service
  * * * * * */
  socket
  createSocketInstance() {
    let socketport = this.configServ.project_config.socketport || 'http://localhost:8070/';
    this.socket = io.connect(socketport);
  }
  public getSocketInstance(): Rx.Subject<any> {
    if (!this.socket) {
      this.createSocketInstance();
    }
    return this.socket;
  }
}
