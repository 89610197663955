import { Injectable } from '@angular/core';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root'
})

export class TagService {
  dfx: any;
  datauxview: any;
  pickElement: boolean = true;

  constructor(private communicationServ: CommunicationService) { }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * * *
  * init dataux instance
  * * * */
  init(_datauxview) {
    this.datauxview = _datauxview;
    this.dfx = _datauxview.getDatascape();
  }

  /* * * * *
  * attch event for dynamic created ship objects
  * * * * * */
  attchDynamicEvent(element) {
    let el = this.datauxview.getElementId(element);
    this.datauxview.getDatascape().attach(el, {
      actions: {
        hoverOn: [(evt, elem) => {
          this.onHover(elem)
        }],
        hoverOff: [(evt, elem) => {
          this.offHover(elem);
        }],
      }
    });
  }

  /* * * * *
  * ship onhover tag will show if lable control is off
  * * * * * */
  onHover(elem) {
  }

  /* * * * *
  * ship offhover shown tag will hide
  * * * * * */
  offHover(elem) {
  }

  /* * * * *
  * add an Observer attach to 3d object
  * * * */
  addObserver(elem) {
    if (!elem) {
      return;
    }
    this.datauxview.getDatascape().attach(elem, {
      observer: {
        callback: this.assignTagProperties.bind(this),
        options: { no2D: false }
      }
    });
  }

  /* * * *
  * Remove an Observer detach to 3d object
  * * * */
  removeObserver(elem) {
    if (!elem) {
      return;
    }
    this.datauxview.getDatascape().detach(elem, {
      observer: {
        callback: this.assignTagProperties.bind(this),
        options: { no2D: false }
      }
    });
  }

  /* * * * *
  * tag observer properties
  * * * */
  assignTagProperties(options) {
    const { id, position, position2D, uuid, tag, visibility } = Object.assign({}, options);
    let elem = this.datauxview.getElementId(id);
    let props = this.datauxview.getDatascape().props(elem);
  }

}
