import { Injectable } from '@angular/core';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root'
})
export class ControllerService {
  datauxview: any;
  dfx: any;
  moe: any = 0.1;
  distance: any = 1800;
  isNscAppLoaded: boolean = false;
  selectedKha: any = 'Buoyancy';
  dragedComponentName: any = "";
  dragedComponentPos: any = {};
  matCurrentTab: any = "system";
  isMaterialAppLoaded: boolean = false;
  selectedSysComp: any = [];
  showSysComp: any = {};
  selectedSysComments: any = {};
  selectedCompComments: any = {};
  shipComp: any = {};

  //RHIB animation ctrls
  rhibSpeed: any = 0.5;
  rhibXRotate: any = 0;
  rhibZRotate: any = -10;
  rhibYRotate: any = -90;

  comps = [
    {
      "component": "KHA", "compList": [
        {
          "system": "Buoyancy", "key": "buoyancy",
          "systemList": [
            { "name": "Eductors", "color": "#FFF", "status": "active", "model": ["Eductors"], 'targetStatus': false, 'visible': false }
          ],
          "status": "active"
        },
        { "system": "Comms", "key": "comms", "systemList": [], "status": "inactive" },
        { "system": "Dangerous Goods", "key": "dangerousgoods", "systemList": [], "status": "inactive" },
        {
          "system": "EER", "key": "eer", "systemList": [
            { "name": "EEBDs", "color": "#FFF", "status": "active", "model": ["EEBDs"], 'targetStatus': false, 'visible': false },
            { "name": "Life Rafts", "color": "#FFF", "status": "active", "model": ["Life Rafts"], 'targetStatus': false, 'visible': false },
            { "name": "Life Buoys", "color": "#FFF", "status": "active", "model": ["Life Buoys"], 'targetStatus': false, 'visible': false }
          ], "status": "active"
        },
        { "system": "Engineering", "key": "engineering", "systemList": [], "status": "inactive" },
        // { "system": "Seamanship", "key": "seamanship", "systemList": [], "status": "inactive" },
        {
          "system": "Fire Safety", "key": "firesafety",
          "systemList": [{ "name": "HALON System", "color": "#FFF", "status": "active", "model": ["HALON System"], 'targetStatus': false, 'visible': false },
          { "name": "Automatic Water Spray", "color": "#FFF", "status": "active", "model": ["Automatic Water Spray"], 'targetStatus': false, 'visible': false },
          { "name": "AFFF System", "color": "#FFF", "status": "active", "model": ["AFFF System"], 'targetStatus': false, 'visible': false },
          { "name": "Twin Agent (TAU)", "color": "#FFF", "status": "active", "model": ["Twin Agent (TAU)"], 'targetStatus': false, 'visible': false },
          { "name": "Fine Water Spray", "color": "#FFF", "status": "active", "model": ["Fine Water Spray"], 'targetStatus': false, 'visible': false },
          { "name": "Galley FSS (KIDDE)", "color": "#FFF", "status": "active", "model": ["Galley FSS (KIDDE)"], 'targetStatus': false, 'visible': false },
          { "name": "MDFP", "color": "#FFF", "status": "active", "model": ["MDFP"], 'targetStatus': false, 'visible': false },
          { "name": "DDFP", "color": "#FFF", "status": "active", "model": ["DDFP"], 'targetStatus': false, 'visible': false },
          { "name": "Firemain", "color": "#FFF", "status": "active", "model": ["Firemain"], 'targetStatus': false, 'visible': false },
          { "name": "Hoses, Nozzles & Fittings", "color": "#FFF", "status": "active", "model": ["Hoses, Nozzles & Fittings"], 'targetStatus': false, 'visible': false },
          { "name": "Dry Chemical Extinguishers", "color": "#FFF", "status": "active", "model": ["Dry Chemical Extinguishers"], 'targetStatus': false, 'visible': false },
          { "name": "CO2 Extinguishers", "color": "#FFF", "status": "active", "model": ["CO2 Extinguishers"], 'targetStatus': false, 'visible': false },
          { "name": "Water Extinguishers", "color": "#FFF", "status": "active", "model": ["Water Extinguishers"], 'targetStatus': false, 'visible': false },
          { "name": "SCBA/Charging System/PDC", "color": "#FFF", "status": "active", "model": ["SCBA/Charging System/PDC"], 'targetStatus': false, 'visible': false },
          { "name": "SCBA/Charging System/HPFA", "color": "#FFF", "status": "active", "model": ["SCBA/Charging System/HPFA"], 'targetStatus': false, 'visible': false },
          { "name": "Locker (Damage Control)", "color": "#FFF", "status": "active", "model": ["Locker (Damage Control)"], 'targetStatus': false, 'visible': false }],
          "status": "active"
        },
        { "system": "Navigation", "key": "navigation", "systemList": [], "status": "inactive" },
        { "system": "Structure", "key": "structure", "systemList": [], "status": "inactive" }
      ]
    },
    { "component": "Compartments", "compList": [] },
    { "component": "Presets", "compList": [
      { "name": "Shafts", "code": ["Shafting"], "selected":false,"status": "active"},
      { "name": "Ladders", "code": ["Ladders-Vertical_and_Sloping"], "selected":false,"status": "active"},
      { "name": "Portable Firefighting", "code": ["Portable_Firefighting"], "selected":false,"status": "active"},
      { "name": "Seawater", "code": ["Aux_Sea_Water_and_Sea_Water_Cooling", 'Aux_Sea_Water_and_Sea_Water_Cooling_Insulation'],"selected":false,"status": "active"},
      { "name": "First-Aid Kits", "code": [], "selected":false,"status": "inactive"},
      { "name": "Life Rafts", "code": [], "selected":false,"status": "inactive"},
      { "name": "Stretchers", "code": [], "selected":false,"status": "inactive"}
    ] },

  ]
  statusList: any = [
    { 'id': 'completed', 'status': 'Completed', 'color': '#99ccff' },
    { 'id': 'noissues', 'status': 'No Issues', 'color': '#00b050' },
    { 'id': 'ontrackminorissues', 'status': 'On Track minor issues', 'color': '#99cc00' },
    { 'id': 'significantissuessuccessfuldeliveryathighrisk', 'status': 'Significant Issues successful delivery at high risk', 'color': '#ffc000' },
    { 'id': 'majorissuespreventingcompletion', 'status': 'Major Issues preventing completion', 'color': '#ff6600' },
    { 'id': 'criticalissuespreventingcompletion', 'status': 'Critical issues preventing completion', 'color': '#ff0000' },
    { 'id': 'notstarted', 'status': 'Not Started', 'color': '#ffffff' }
  ];
  ernMappingList: any = [
    { name: "Damage Control Systems", key: 'ern-damagecontrol' },
    { name: "Main Propulsion System", key: 'ern-populasion' },
    { name: "Electrical Power", key: 'ern-electricalpower' },
    { name: "Marine Engineering Aux Equipment Group", key: 'ern-marine', status: 'active' },
    { name: "Deck & Hull Equipment Group", key: 'ern-deckhull' },
    { name: "Hull System & Fittings", key: 'ern-hullsystems' },
    { name: "Main Refrigeration &  HVAC Systems", key: 'ern-hvac' },
    { name: "Machinery Control & Surveillance Systems", key: '' }
  ]
  constructor(private communicationServ: CommunicationService) { }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * * *
  * init dataux
  * * * */
  init(_datauxview) {
    this.datauxview = _datauxview;
    this.dfx = _datauxview.getDatascape();
  }

  /* * * *
  * move the object position
  * * * */
  move(element, posObj) {
    if (element) {
      let props = this.dfx.props(element);
      var data = { "time": "1", "op": "moveTo", "name": props.id, "duration": 1.720, "place": { "pos": posObj } }
      this.datauxview.modifyElement(data);
    }
  }

  /* * * *
  * rotate the object
  * * * */
  rotate(element, ang) {
    if (element) {
      let props = this.dfx.props(element);
      var data = { "time": "1", "op": "moveTo", "name": props.id, "duration": 1.720, "place": { "rot": ang } }
      this.datauxview.modifyElement(data);
    }
  }

  /* * * *
  * check if object is visible or not
  * * * */
  isObjVisible(c) {
    let comp = this.datauxview.getElementId(c);
    if (comp) {
      let props = this.dfx.props(comp);
      let vis = props.profile.visible;
      return vis;
    } else {
      return null;
    }
  }

  /* * * * *
  * show particluar element
  * * * * * */
  showElement(name) {
    let element = this.datauxview.getElementId(name);
    if (element) {
      var _obj = { "profile": 'regular' };
      this.dfx.modify(element, _obj);
    }
  }

  /* * * * *
  * hide particluar element
  * * * * * */
  hideElement(name) {
    let element = this.datauxview.getElementId(name);
    if (element) {
      var _obj = { "profile": 'invisible' };
      this.dfx.modify(element, _obj);
    }
  }

  /* * * * *
  * transparent particluar element
  * * * * * */
  transparentElement(name) {
    let element = this.datauxview.getElementId(name);
    if (element) {
      var _obj = { "profile": 'transparent' };
      this.dfx.modify(element, _obj);
    }
  }

  modify(name) {
    let elem = this.datauxview.getElementId(name);
    if (!elem) return;
    // let posArray = [{ x: -0.366, y: 1.9583, z: -1.1573 }, { x: -0.3481, y: 1.4872, z: -1.1884 }, { x: -0.6015, y: 1.4123, z: -0.7494 }, { x: -0.6754, y: 1.7734, z: -0.6214 }, { x: -0.8094, y: 1.8252, z: -0.0082 }, { x: -0.966, y: 1.5974, z: 0.2681 }, { x: -1.2558, y: 1.7761, z: 0.7702 }, { x: -1.3462, y: 1.3, z: 0.9267 }, { x: -1.4806, y: 0.7016, z: 1.1595 }, { x: -1.5051, y: 0.185, z: 1.2019 }, { x: -1.204, y: 0.0287, z: 0.6804 }, { x: -0.7601, y: -0.1567, z: -0.0885 }, { x: -0.0145, y: 0.0284, z: -1.3799 }, { x: -1.434, y: 1.686, z: 1.0788 }, { x: -1.1077, y: 1.0131, z: 0.5136 }, { x: -0.0264, y: 0.3365, z: -1.3593 }];
    let posArray = [{ x: -0.1109, y: 1.7804, z: -1.2129 }, { x: -0.4391, y: 1.763, z: -0.6444 }, { x: -0.8305, y: 1.7457, z: 0.0334 }, { x: -1.1162, y: 1.7281, z: 0.5283 }, { x: -1.3388, y: 1.497, z: 0.9138 }, { x: -1.4316, y: 1.1058, z: 1.0746 }, { x: -1.0383, y: 1.2656, z: 0.3933 }, { x: -0.5089, y: 1.1472, z: -0.5236 }, { x: -0.0812, y: 0.9581, z: -1.2644 }, { x: -1.4498, y: 0.4783, z: 1.1061 }, { x: -1.4747, y: 0.1156, z: 1.1492 }, { x: -1.1143, y: -0.0478, z: 0.5249 }, { x: -0.6151, y: -0.2181, z: -0.3397 }, { x: -0.0131, y: 0.0082, z: -1.4278 }, { x: -0.2082, y: 0.3468, z: -1.7657 }, { x: -0.2797, y: 1.0082, z: -1.8896 }, { x: -0.1446, y: 1.3539, z: -1.6555 }];
    let pos = posArray[Math.floor(Math.random() * posArray.length)];
    var _obj = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z } } }
    this.dfx.modify(elem, _obj);
  }

  /* * * * *
  * modify element
  * * * * * */
  modifyElement(name, pos=null,rot=null) {
    let elem = this.datauxview.getElementId(name);
    if (!elem) return;
    var _obj = { 'geometry': { 'orientation': rot||{ x: 0, y: 0, z: 0 }, position: pos||{ x: 0, y: 0, z: 0 } } };
    //_obj['geometry']['position'] = pos;
    this.dfx.modify(elem, _obj);
  }

  /* * * * *
  * modify orientation element
  * * * * * */
  modifyOrientation(name, angle) {
    let elem = this.datauxview.getElementId(name);
    if (!elem) return;
    var _obj = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 } } };
    _obj['geometry']['orientation'] = Object.assign(this.dfx.props(elem).geometry.orientation,angle) ;
    this.dfx.modify(elem, _obj);
  }

  /* * * * *
  * show particluar element for child element
  * * * * * */
  showChildMesh(mesh, matid) {
    if (mesh) {
      if (matid) {
        let scene = this.dfx.getCamera().getScene();
        let mat = scene.getMaterialByName(mesh.defMat || matid);
        mesh.material = mat;
        mesh.getChildMeshes().forEach((m) => {
          mat = scene.getMaterialByName(m.defMat || matid);
          m.material = mat;
          m.isVisible = true;
        })
      }
      mesh.isVisible = true;
    }
  }

  /* * * * *
  * hide particluar element for child element
  * * * * * */
  hideChildMesh(mesh) {
    if (mesh) {
      let scene = this.dfx.getCamera().getScene();
      var matid = 'hidden_obj';
      let mat = scene.getMaterialByName(matid);
      mesh.material = mat;
      mesh.isVisible = false;
      mesh.getChildMeshes().forEach((m) => {
        m.material = mat;
        m.isVisible = false;
      })
    }
  }

  /* * * * *
  * transparent particluar element for child element
  * * * * * */
  transparentChildMesh(mesh) {
    if (mesh) {
      let scene = this.dfx.getCamera().getScene();
      var matid = 'transparent';
      let mat = scene.getMaterialByName(matid);
      mesh.material = mat;
      mesh.isVisible = true;
      mesh.getChildMeshes().forEach((m) => {
        m.material = mat;
        m.isVisible = true;
      })
    }
  }
  getMeshName(n) {
    if (!n) {
      return 'unknown'
    }
    let arr = n.split(".");
    if (arr.length === 1) {
      return arr[0]
    }
    if (arr.length === 0) {
      return "unknown"
    }
    if (arr[arr.length - 1].indexOf('00') === 0) {
      return arr[arr.length - 2] + "." + arr[arr.length - 1]
    }
    return arr[arr.length - 1];
  }
  getMesh(id) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    return mesh;
  }
  getChildMesh(id, cid) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    let meshes = mesh.getChildMeshes();
    let idx = meshes.findIndex(e => e.name.includes("." + cid));
    if (idx > -1) {
      return meshes[idx];
    }
    return null;
  }
  getChildMeshEx(id, cid) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    let meshes = mesh.getChildMeshes();
    let idx = meshes.findIndex(e => this.getMeshName(e.name) === cid);
    if (idx > -1) {
      return meshes[idx];
    }
    return null;
  }
  getMeshID(m) {
    let aname = m.id.split(".");
    let name = aname[aname.length - 1];
    return name;
  }
  getMeshByID(_id) {
    let dfx = this.datauxview.getDatascape();
    let ship = this.datauxview.getElementId('imodel');
    let mesh = dfx.getElementMesh(ship);
    let id = _id.toLowerCase();
    let meshes = mesh.getChildMeshes();
    let l = meshes.length;
    let i = 0;
    let __mesh = null;
    for (; i < l; i++) {
      let _m = meshes[i];
      let mid = this.getMeshID(_m).toLowerCase();
      if (mid === id) {
        __mesh = _m;
        break;
      }
    }
    return __mesh;
  }

  /* * *
  * update system comps
  * * */
  updateComps(_comps) {
    const idx = this.comps.findIndex(el => el.component.toLowerCase() === 'kha');
    this.comps[idx]["compList"].forEach(item => {
      if (item['key'] === this.selectedKha.split(" ").join("").toLowerCase()) {
        item['systemList'].forEach(sys => {
          if (sys['model']) {
            sys['model'].forEach(comp => {
              let found = _comps.some(el => comp.endsWith(el))
              if (found) {
                sys['status'] = 'active';
              } else {
                sys['status'] = 'inactive';
              }
            });
          }
        })
      }
    })
  }
  updateSysComps(_comps, boo) {
    let showAll;
    this.showSysComp[this.selectedKha] = [];
    const idx = this.comps.findIndex(el => el.component.toLowerCase() === 'kha');
    this.comps[idx]["compList"].forEach(item => {
      if (item['key'] === this.selectedKha.split(" ").join("").toLowerCase()) {
        item['systemList'].forEach(sys => {
          if (sys['model']) {
            sys['model'].forEach(comp => {
              let found = _comps.some(el => comp.endsWith(el))
              if (found) {
                sys['status'] = boo ? "active" : 'inactive';
              }
              if (sys['status'] === 'active') {
                this.showSysComp[this.selectedKha].push(comp);
              }
            });
          }
        })
        showAll = item['systemList'].some(sys => sys['status'] == 'inactive');
      }
    });
    let _boo = showAll ? false : true;
    this.broadcastInfo({ src: 'ctrlServ', event: 'update-show-all', data: _boo, key: "" });
  }
}
