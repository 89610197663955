import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { CommunicationService } from "../services/communication.service";
import { ConfigService } from '../services/config.service';
import { ControllerService } from '../services/controller.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
// import { GraphicsManager } from '../services/graphFDL/graphicsManager';
import { threadId } from 'worker_threads';
import { ThrowStmt } from '@angular/compiler';
// import * as XLSX from 'xlsx';

@Component({
  selector: 'app-sidepanel',
  templateUrl: './sidepanel.component.html',
  styleUrls: ['./sidepanel.component.scss']
})
export class SidepanelComponent implements OnInit {
  @Output() onBoatControlChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() pathAnim: boolean = false;
  @Input() rhibSpeed;
  mode: ProgressSpinnerMode = 'determinate';
  handleToggleOpen: boolean = false;
  handleToggleOpenNwm: boolean = false;
  handleInsToggleOpen: boolean = false;
  showToolsPanelOpen: boolean = true;
  engineAnim: boolean = false;
  rhibAnim: boolean = false;
  rhibAnimVer2: boolean = false;
  handleToolsToggleOpen: boolean = false;
  courseHandleToggleOpen: boolean = false;
  coursePanelOpen: boolean = false;
  controlPosition: any = 'Right';
  controlDPosition: any = 'Left';
  selected_demo: string = "";
  ship_comp_ctl: Array<any>;
  ship_comp_ctl_list: any = [];
  handleDToggleOpen: boolean = false;
  global_pause = false;
  broadcastSubs: any;
  selectedKha: any = 'rcn-martech';
  formData = new FormData();
  selectedFile: any = 'No file chosen...';
  showBlockLoading: boolean = false
  /***
   * Color picker start
   */
  nscSearch: any = "";
  networkData: any = [];
  activeIndex: any = [];
  infoPanel: any;
  NSCTab: any;
  networkDataDefault: any;
  nscShowPanelOpen: any = false;
  public toggle: boolean = false;
  idgSearch: any = "";
  public rgbaText: string = 'rgba(165, 26, 214, 0.2)';
  deckList: any = ['02', '01', '1', 'M', '2', '3', '4', '5'];
  public colorList = [
    { key: "flame", value: "#e45a33", friendlyName: "Flame" },
    { key: "orange", value: "#fa761e", friendlyName: "Orange" },
    { key: "infrared", value: "#ef486e", friendlyName: "Infrared" },
    { key: "male", value: "#4488ff", friendlyName: "Male Color" },
    { key: "female", value: "#ff44aa", friendlyName: "Female Color" },
    { key: "paleyellow", value: "#ffd165", friendlyName: "Pale Yellow" },
    { key: "gargoylegas", value: "#fde84e", friendlyName: "Gargoyle Gas" },
    { key: "androidgreen", value: "#9ac53e", friendlyName: "Android Green" },
    { key: "carribeangreen", value: "#05d59e", friendlyName: "Carribean Green" },
    { key: "bluejeans", value: "#5bbfea", friendlyName: "Blue Jeans" },
    { key: "cyancornflower", value: "#1089b1", friendlyName: "Cyan Cornflower" },
    { key: "warmblack", value: "#06394a", friendlyName: "Warm Black" },
  ];
  public animateControls: any = [
    {
      'id': 'Thruster027', 'title': 'Thruster rear-left', 'subList': [
        { 'pid': 'Thruster027', 'id': 'Thruster027', 'title': 'Rotate', 'animateStatus': 'stop', "action": "rotate", 'data': { axis: "y", dir: 1, min: -30, max: 30, value: 0, step: 1 }, 'playcontrol': false }
        , { 'pid': 'Thruster027', 'id': 'Thruster027', 'title': 'RPM', 'animateStatus': 'stop', "action": "rpm", 'data': { dir: 1, min: 1, max: 1000, value: 250, step: 10, "id": "01" }, 'playcontrol': false }

      ]
    },
    {
      'id': 'Thruster026', 'title': 'Thruster rear-right', 'subList': [
        { 'pid': 'Thruster026', 'id': 'Thruster026', 'title': 'Rotate', 'animateStatus': 'stop', "action": "rotate", 'data': { axis: "y", dir: -1, min: -30, max: 30, value: 0, step: 1 }, 'playcontrol': false }
        , { 'pid': 'Thruster026', 'id': 'Thruster026', 'title': 'RPM', 'animateStatus': 'stop', "action": "rpm", 'data': { dir: 1, min: 1, max: 1000, value: 250, step: 10, "id": "02" }, 'playcontrol': false }

      ]
    },
    {
      'id': 'Thruster025', 'title': 'Thruster front-right', 'subList': [
        { 'pid': 'Thruster025', 'id': 'Thruster025', 'title': 'Rotate', 'animateStatus': 'stop', "action": "rotate", 'data': { axis: "y", dir: 1, min: -30, max: 30, value: 0, step: 1 }, 'playcontrol': false }
        , { 'pid': 'Thruster025', 'id': 'Thruster025', 'title': 'RPM', 'animateStatus': 'stop', "action": "rpm", 'data': { dir: 1, min: 1, max: 1000, value: 250, step: 10, "id": "03" }, 'playcontrol': false }

      ]
    },
    {
      'id': 'Thruster022', 'title': 'Thruster front-left', 'subList': [
        { 'pid': 'Thruster022', 'id': 'Thruster022', 'title': 'Rotate', 'animateStatus': 'stop', "action": "rotate", 'data': { axis: "y", dir: -1, min: -30, max: 30, value: 0, step: 1 }, 'playcontrol': false }
        , { 'pid': 'Thruster022', 'id': 'Thruster022', 'title': 'RPM', 'animateStatus': 'stop', "action": "rpm", 'data': { dir: 1, min: 1, max: 1000, value: 250, step: 10, "id": "04" }, 'playcontrol': false }

      ]
    },
    {
      'id': 'Camera', 'title': 'Camera ', 'subList': [
        { 'pid': 'Box060', 'id': 'Box060', 'title': 'Rotate', 'animateStatus': 'stop', "action": "rotate", 'data': { axis: "z", dir: 1, min: -30, max: 30, value: 0, step: 1 }, 'playcontrol': false }
      ]
    },
    {
      'id': 'Box063', 'title': 'Light ', 'subList': [
        { 'pid': 'Box063', 'id': 'Box063', 'title': 'Rotate', 'animateStatus': 'stop', "action": "rotate", 'data': { axis: "y", dir: 1, min: -30, max: 30, value: 0, step: 1 }, 'playcontrol': false }
      ]
    }
  ]
  public presetValues: string[] = [];
  public selectedColor: string = 'color1';
  disableTransCtrl: boolean = false;
  startAnim: boolean = false;
  getColorValues() {
    return this.colorList.map(c => c.value);
  }
  /***
   * Color picker end
   */
   showInsPanelOpen: boolean = true;
  showDrawerPanelOpen: boolean = false;
  handleDrawerToggleOpen: boolean = false;
  showAssemblePanelOpen: boolean = false;
  handleAssembleToggleOpen: boolean = false;
  showPanelOpen: any;
  showDPanelOpen: any;
  showControlPanelOpen: any = true;
  handleControlToggleOpen: any = true;
  showDControlPanelOpen: any = false;
  handleLeftControlToggle: any = false;
  showLeftControlPanelOpen: any = true;
  handleDControlToggleOpen: any = false;
  quizPanelOpen: any = false;
  quizHandleToggleOpen: any = false;
  docked = false;
  explodeState: any = false;
  shipChildCompsCntlsList: any = [];
  shipChildCompsCntl: any = [];
  testMode: any = '';
  moe: any = 0.1;
  componentModel: any = "solid"; 
  shipRulerShow: any = false;
  // @Input() appMode: any = "idg";
  animationMode:any=false;
  @Input() set animationIDG(v:any){
    this.animationMode=v;

    if(this.animationMode){
      this.showToolsPanelOpen = true;
      this.showIDGEngineAnim({checked:true})
    }else{
      this.showToolsPanelOpen = false;
    }
  }
  _appMode="idg"
  @Input() set appMode(v: any) {
    this.activeModel = v;
    this._appMode=v;
    if (v == 'idgo') {
      this.showInsPanelOpen = false;
      this.showToolsPanelOpen = false;
    }
    if (v == 'idg') {
      if(this.animationMode){
        this.showToolsPanelOpen = true;
      }else{
        this.showToolsPanelOpen = true;
      }
     
    }
    if (v == 'inexxt') {
      this.showToolsPanelOpen = false;
    }
    if (v == 'kys') {
      this.showToolsPanelOpen = true;
      this.shipRulerShow = true;
    }
    if (v == 'mepm') {
      this.showToolsPanelOpen = true;
      this.shipRulerShow = true;
    }
    if (v == 'fmfcb') {
      this.showToolsPanelOpen = true;
      this.shipRulerShow = true;
    }
    if (v == 'msd') {
      this.showToolsPanelOpen = true;
      this.shipRulerShow = true;
    }
    if(v == "rhib"){
      this.showToolsPanelOpen = true;
    }
  }
  @Input() set appGameMode(v: any) {
    if (v == true) {
      this.showInsPanelOpen = false;
      this.showToolsPanelOpen = false;
      this.broadcastInfo({ src: 'sidepanel', event: 'showhide-ruler', key: false, data: '' });
      this.broadcastInfo({ src: 'sidepanel', event: 'showhide-fl', key: false, data: '' });
    }
  }
  @Input() selectedTree: any;
  @Input() activeModel: any = "idg";
  // @Input() set activeModel(v: any) {
  //   if (v == 'idgo') {
  //     this.showInsPanelOpen = false;
  //   }
  // }
  appModeDtails: any;
  nmCompMiniList: any;
  maxList: any;
  @Input() quizList: any = [];
  @Input() shipObjects: any = [];
  @Input() focusMode: boolean = false;
  @Input() show_ern: boolean = false;
  @Input() set controlPanelState(v: any) {
    this.handleLeftControlToggle = v;
  }
  @Input() set shipChildCompsCntls(v: any) {
    this.shipChildCompsCntl = v;
    this.shipChildCompsCntlsList = v;
  };
  @Input() set showDPanel(v: any) {
    console.log("showDPanel", v);
    this.handleDToggleOpen = v;
    this.showDPanelOpen = v;
    this.showDControlPanelOpen = v;
    this.handleDControlToggleOpen = v;
  };
  @Input() set showSSPanel(v: any) {
    console.log("showSSPanel", v);
    this.showPanelOpen = v;
    this.handleToggleOpen = v;
    // this.showControlPanelOpen = v;
    // this.handleControlToggleOpen = v;
  };
  @Input() set showDrawerPanel(v: any) {
    // this.showDrawerPanelOpen = v;
    // this.handleDrawerToggleOpen = v;
  };
  @Input() set showNSCPanel(v: any) {
    console.log("showNSCPanel", v);
    // this.nscShowPanelOpen = v
    this.handleToggleOpenNwm = v;
    if (this.appModeDtails == 'idg') {
      this.showPanelOpen = v;
    }
    if (this.appModeDtails == 'idgo') {
      this.showPanelOpen = v;
    }
  };
  @Input() set appCourseAnimation(v: any) {
    if (v) {
      if (v == 'idg') {
        this.appCourseAnimation = true;
        delete this.selectedTree;
        this.broadcastInfo({ src: 'sidepanel', event: 'idg-engineAnim-node', data: v });
      }
      if (v == 'idgo') {
        this.appCourseAnimation = true;
        delete this.selectedTree;
        this.broadcastInfo({ src: 'sidepanel', event: 'idg-engineAnim-node', data: v });
      }
      if (v == 'idg-animation') {
        delete this.selectedTree;
        this.showIDGEngineAnim(true)
        this.showIDGEngineSmoke(true)
        this.broadcastInfo({ src: 'sidepanel', event: 'idg-engineAnim-node', data: v });
      }
    }
  };
  // appCourseAnimation: boolean = false;
  
  // @Input() set appMode(v: any){
  //   this.appModeDtails = v;
  //   if(this.appModeDtails=='network'){
  //     this.nscShowPanelOpen = true
  //     // this.handleToggleOpenNwm = true;
  //     // this.showPanelOpen = true;
  //   }
  // }
  @Input() set nmCompMiniLists(v: any) {
    console.log("nmCompMiniLists:", v)
    this.nmCompMiniList = v;
  }
  @Input() set resetShipCompsCntls(v: any) {
    if (v) {
      this.resetCompCtls();
      this.groupCheckArray = this.resetgroupCheckArray;
    }
  }
  multiTagStatus: any = false;
  @Input() set multipleTagStatus(v: any) {
    this.multiTagStatus = v
  }
  groupCheckArray: any;
  resetgroupCheckArray: any;
  bckupShip_comp_ctl_list: any = [];
  @Input() set shipCompsCntls(v: any) {
    this.ship_comp_ctl = v;
    this.ship_comp_ctl_list = v;
  }
  @Input() set _disableTrans(v: any) {
    this.disableTransCtrl = v;
  }
  _view = 'solid';
  viewmode = { 'solid': false, 'texture': true, "wireframe": false }
  @Input() set view(v: any) {
    if (v !== this._view) {
      this._view = v;
      this.transparentStatus = this.viewmode[this._view];
      this.onTransCtrl({ checked: this.transparentStatus }, 'showhidetrans');
    }
  }
  popupGroupList
  @Input() set cameraGroupList(v: any) {
    console.log("popupGroupList", v)
    this.popupGroupList = v;
  }
  @Input() animStatus;
  @Input() set _testMode(v: any) {
    this.testMode = v;
    if (v == 'quiz') {
      this.showPanelOpen = false;
      this.handleToggleOpen = false;
      this.quizHandleToggleOpen = false;
      this.quizPanelOpen = false;
      this.showDrawerPanelOpen = false;
      this.handleDrawerToggleOpen = false;
    } else if (v == 'assemble-parts') {
      this.showPanelOpen = false;
      this.handleToggleOpen = false;
      this.quizHandleToggleOpen = false;
      this.quizPanelOpen = false;
      this.showAssemblePanelOpen = true;
      this.handleAssembleToggleOpen = true;
      this.showDrawerPanelOpen = false;
      this.handleDrawerToggleOpen = false;
    } else if (v.includes("game-mode")) {
      this.pathAnim = true;
      this.handleToolsToggleOpen = false;
    }
  }

  @Output() controlChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() cameraTargetClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() showAllCompCtrl: EventEmitter<any> = new EventEmitter<any>();
  @Output() settingPanelState: EventEmitter<any> = new EventEmitter<any>();
  appSideNavList: any = [{
    "appName": "Course Summary",
    "keyName": "course",
    "key": "course",
    // "logo": "../assets/images/KHA_logo.png",
    "disabled": false,
    "subkey": "course_list",
    "childList": [
      {
        "appName": "Compartments",
        "keyName": "comp",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "comp_course",
      },
      {
        "appName": "Stretcher Locations",
        "keyName": "strloc",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "strloc_course",
      },
      {
        "appName": "First Aid Lockers",
        "keyName": "firstAidLoc",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "firstAid_course",
      },
      {
        "appName": "Escape Hatches",
        "keyName": "escHat",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "escHat_course",
      },
      {
        "appName": "Location of chemox",
        "keyName": "chemox",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "chemox_course",
      },
      {
        "appName": "First Aid Extinguishers",
        "keyName": "firstAidExt",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "firstAidExt_course",
      },
      {
        "appName": "Fire Main System",
        "keyName": "fireMain",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "fireMain_course",
      },
      {
        "appName": "Quartzoid sprinkler system",
        "keyName": "quartzoid",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "quartzoid_course",
      },
      {
        "appName": "Magazine flood and spray system",
        "keyName": "magazine",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "magazine_course",
      },
      {
        "appName": "Fitted AFFF system",
        "keyName": "fittAf",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "fittAf_course",
      },
      {
        "appName": "Fitted Halon System",
        "keyName": "fittHa",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "fittHa_course",
      },
      {
        "appName": "Galley Fire Protection",
        "keyName": "gallery",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "gallery_course",
      },
      {
        "appName": "Twin Agent Unit (TAU)",
        "keyName": "twinAgent",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "twinAgent_course",
      },
      {
        "appName": "Fine Water Spray System",
        "keyName": "fineWater",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "fineWater_course",
      },
      {
        "appName": "DC Equipment ",
        "keyName": "equipment",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "equipment_course",
      },
      {
        "appName": "Shincom, Main Broadcast and PRC",
        "keyName": "shincom",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "shincom_course",
      },
      {
        "appName": "Floodable Lockers",
        "keyName": "floodable",
        "key": "course",
        "disabled": false,
        "url": "",
        "subkey": "floodable_course",
      }
    ],
    "url": "",
    "child": false
  }];
  shipComp: any = {};
  constructor(private ctrlServ: ControllerService, private configService: ConfigService, protected html_sanitizer: DomSanitizer, private matIconRegistry: MatIconRegistry, private communicationServ: CommunicationService,) {
    this.presetValues = this.getColorValues();
    matIconRegistry.addSvgIcon(
      '2d-icon',
      html_sanitizer.bypassSecurityTrustResourceUrl("../assets/images/2d-icon.svg")
    );
    matIconRegistry.addSvgIcon(
      '3d-icon',
      html_sanitizer.bypassSecurityTrustResourceUrl("../assets/images/3d-icon.svg")
    );
  }

  ngOnInit() {
    console.log('appMode', this.selectedTree);
    // this.shipChildCompsCntlsList = this.shipChildCompsCntls;
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src == "starterapp") {
          if (data.event === 'drill-down') {
            this.ship_comp_ctl = data.data
            this.shipChildCompsCntl = this.ship_comp_ctl;
            this.ship_comp_ctl_list = this.ship_comp_ctl;
            this.shipChildCompsCntlsList = this.shipChildCompsCntl;
            this.bckupShip_comp_ctl_list = JSON.stringify(this.ship_comp_ctl_list)
            console.log("ship_comp_ctl_list", this.ship_comp_ctl_list)
          }
          if (data.event === 'mechanical') {
            this.selectedTree = 'mechanical';
          }
        }
        if (data.src === "sidepanel") {
          if(data.event === "showhide-deck-labels"){
            this.showlabels= data.key;
            this.showHideDeckLabels();
          }
          if (data.event === "toggleocean") {
            let boo = data['data'];
            // this.toggleOceanState = boo;
          }
          if (data.event === "startAnim") {
            this.startAnim = data.startAnim;
          }
          if(data.event == "show-exit-work"){
            this.showHideExitRoute(data['data']);
          }
        }
        if (data.src === "khaservice") {
          if (data.event === 'update-compartments') {
            this.shipComp['compList'] = data['data'];
            this.sortCompartment('name');
          }
        }
        if (data.src === "main") {
          if (data.event === 'updatectrl') {

          }
          if (data.event === 'showall') {
            this.showAll();
          }
          if (data.event === 'hideall') {
            this.hideAll();
          }
          if (data.event === 'savestate') {
            this.saveState();
          }
          if (data.event === 'loadstate') {
            this.loadState();
          }
        }
        if (data.src === "material") {
          if(data.event == "quiz-sidepanel-hide"){
            if(data.data=='kys-quiz-mode'){
              this.showToolsPanelOpen = false;
            }      
            if(data.data == "kys"){
              this.showruler = true;
              this.showHideRuler();
              this.showFL=true;
              this.showHideFL();
            }
          }
        }
        if (data.src === "networkmodel") {
          if (data.event === "search-ern") {
            this.nscSearch = (data['data']);
            this.nscSearchValue({});
          }
          if (data.event === "nmCompMiniAddDetails") {
            this.nmCompMiniList = data['miniData']
            this.maxList = data['maxData']
          }
          if (data.event === 'updatenetworkdata') {
            this.networkData = data['data']['vertices'];
            this.networkDataDefault = data['data']['vertices'];
            // this.nscSearch = data['key'];
            this.orderTreeData();
            // if (this.nscSearch) {
            //   setTimeout(() => {
            //     this.nscSearchValue({});
            //   }, 1000);
            // }
            console.log("updatenetworkdata", this.networkData)
          }
        }

        // if(data.src ==="detailpanel"){
        //   if(data.event === "nmCompClose"){
        //     this.nmCompClose(data.data)
        //   }
        // }
        if (data.src === "main") {
          if (data.event === 'show-quiz-panel') {
            if (data.key) {
              this.showPanelOpen = false;
              this.handleToggleOpen = false;
              this.showAssemblePanelOpen = false;
              this.handleAssembleToggleOpen = false;
              this.quizHandleToggleOpen = true;
              this.quizPanelOpen = true;
              this.showDrawerPanelOpen = false;
              this.handleDrawerToggleOpen = false;
            } else {
              this.showPanelOpen = false;
              this.handleToggleOpen = false;
              this.quizHandleToggleOpen = false;
              this.quizPanelOpen = false;
              this.showDrawerPanelOpen = false;
              this.handleDrawerToggleOpen = false;
            }
          }
          if (data.event === 'show-assemble-panel') {
            if (data.key) {
              this.showPanelOpen = false;
              this.handleToggleOpen = false;
              this.quizHandleToggleOpen = false;
              this.quizPanelOpen = false;
              this.showAssemblePanelOpen = true;
              this.handleAssembleToggleOpen = true;
              this.showDrawerPanelOpen = false;
              this.handleDrawerToggleOpen = false;
            } else {
              this.showPanelOpen = false;
              this.handleToggleOpen = false;
              this.quizHandleToggleOpen = false;
              this.quizPanelOpen = false;
              this.showAssemblePanelOpen = false;
              this.handleAssembleToggleOpen = false;
              this.showDrawerPanelOpen = false;
              this.handleDrawerToggleOpen = false;
            }
          }
          if (data.event === 'update-quiz-data') {
            let obj = data['data'];
            if (obj) {
              this.quizList = obj;
            }
          }
          if (data.event === 'show-focus-mode') {
            let info = data['data'];
            if (info) {
              this.onLocCtl('locate', info.id, 'Components', info.name);
            }
          }
          if (data.event === 'close-focus-mode') {
            let info = data['data'];
            if (info == false) {
              this.componentModel = 'solid';
            }
          }
          if (data.event === 'shipCompsCntls') {
            let obj = data['data'];
            this.ship_comp_ctl = obj.Components;
            this.ship_comp_ctl_list = obj.Components;
          }

        }
        if (data.src === "controls") {
          if (data.event === 'explode') {
            if (data.key === '') {
            } else {
              this.explodeState = data.key;
            }

          }
          if (data.event === 'explode_off') {
            this.explodeState = false;
          }
        }
        if (data.src === "staterapp") {
          if (data.event === 'shipChildComps') {
            this.shipChildCompsCntl = data['data'];
            this.shipChildCompsCntlsList = data['data'];
            this.focusMode = true;
            this.disableTransCtrl = true;
          }
          if (data.event === 'loadShipComps') {
            if (data['data'] == 'reset') {
              this.focusMode = false;
              this.disableTransCtrl = false;
              this.checkComponentStatus();
              // this.showAll();
              // this.resetCompCtls();
              this.componentModel = 'solid';
            }
          }
        }
        if (data.src === "rhib") {
          if (data.event === 'toggleOceanState') {
            this.toggleOceanState = data['data'];
          }
        }
        // if (data.src === "coursePanel") {
        //   if(data.event === "updateIdgAnimation"){
        //     let course = data['data'];
        //       this.appCourseAnimation = true;
        //   }
        // }
      })
      // console.log("ship_comp_ctl_list", this.ship_comp_ctl_list)
      
  }
  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  ctrlState: any = {};
  saveState() {
    this.ctrlState.trans = this.transparentStatus;
    this.ctrlState.group = JSON.stringify(this.groupCheckArray || []);
  };
  loadState() {
    this.transparentStatus = this.ctrlState.trans;
    this.groupCheckArray = JSON.parse(this.ctrlState.group);
    if (!this.groupCheckArray.length) {
      this.groupCheckArray = null;
    }
    this.onTransCtrl({ checked: this.transparentStatus }, 'showhidetrans');
    for (let m in this.ship_comp_ctl) {
      let catg = this.ship_comp_ctl[m];
      catg.forEach((c) => {
        let info = this.getCtrlData(c);
        if (info && info.isSub) {
          this.onCheckCtl({ checked: info.selected }, "solidtrans", c)
        }
      })
    }
  };

  ToggleOpenHanddle() {
    this.handleToggleOpen = !this.handleToggleOpen;
    if (this.handleToggleOpen) {
      var controlChange = {
        event: 'inpect',
        name: '',
        state: true
      }
      this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
      // this.controlChange.emit({ event: 'inpect', name: "", state: true });
    }
  }
  ToggleDrawerOpenHanddle() {
    this.handleDrawerToggleOpen = !this.handleDrawerToggleOpen;
  }
  ToggleAssembleOpenHanddle() {
    this.handleAssembleToggleOpen = !this.handleAssembleToggleOpen;
    // if (this.handleToggleOpen) {
    //   this.controlChange.emit({ event: 'inpect', name: "", state: true });
    // }
  }
  quizToggleOpenHanddle() {
    this.quizHandleToggleOpen = !this.quizHandleToggleOpen;
  }
  ToggleOpenHanddleNwm() {
    this.handleToggleOpenNwm = !this.handleToggleOpenNwm;
    // if (this.handleToggleOpen) {
    //   this.controlChange.emit({ event: 'inpect', name: "", state: true });
    // }
  }
  ToggleOpenHanddleIns() {
    this.handleInsToggleOpen = !this.handleInsToggleOpen;
  }
  ToggleControlOpenHanddle() {
    this.handleControlToggleOpen = !this.handleControlToggleOpen;
  }
  ToggleDOpenHanddle() {
    this.handleDToggleOpen = !this.handleDToggleOpen;

  }
  ToggleDControlOpenHanddle() {
    this.handleDControlToggleOpen = !this.handleDControlToggleOpen;
    if (this.handleDControlToggleOpen) {
      var controlChange = {
        event: 'operate',
        name: '',
        state: true
      }
      this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
      // this.controlChange.emit({ event: 'operate', name: "", state: true });
    }
  }
  ToggleToolOpenHanddle() {
    this.handleToolsToggleOpen = !this.handleToolsToggleOpen;
  }
  courseToggleOpenHanddle() {
    this.courseHandleToggleOpen = !this.courseHandleToggleOpen;
  }
  resetAnimStatus(s) {
    this.animateControls.forEach((c) => {
      c.subList.forEach((e) => {
        if (s === 'stop') {
          e.animateStatus = s;
        }
        if (s === 'pause' && e.animateStatus == 'play') {
          e.animateStatus = s;
        }
        if (s === 'play' && e.animateStatus == 'pause') {
          e.animateStatus = s;
        }
      })
    })
  }
  setAnimStatusOf(data) {
    this.animateControls.forEach((c) => {
      if (c.id === data.key) {
        c.subList.forEach((e) => {
          if (e.data === data.sub) {
            e.animateStatus = data.state;
          }
        })
      }

    })
  }
  clearAllAnim() {
    this.resetAnimStatus("stop");
    var controlChange = {
      event: 'demo_anim',
      name: 'stop',
      state: 'stopall'
    }
    this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
    // this.controlChange.emit({ event: 'demo_anim', name: "stop", state: "stopall" });
  }
  pauseAllAnim(e) {
    this.global_pause = !this.global_pause;
    this.resetAnimStatus(this.global_pause ? "pause" : "play");
    var controlChange = {
      event: 'demo_anim',
      name: 'pause',
      state: e.checked
    }
    this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
    // this.controlChange.emit({ event: 'demo_anim', name: "pause", state: e.checked });
  }
  showAll() {
    let dom = document.getElementsByClassName("side_panel")[0].getElementsByClassName('transToggle')[0];
    if(dom){
      let el: any = dom.getElementsByTagName("mat-slide-toggle")[0].getElementsByTagName("INPUT")[0];
      if (el.checked) {
        el.click();
      }
    }

    let shipcomp = Object.keys(this.ship_comp_ctl);

    shipcomp.forEach((key) => {
      let dom = document.getElementsByClassName("side_panel")[0].getElementsByClassName(key)[0];
      if(dom){
        if(dom.getElementsByTagName("mat-slide-toggle")[0]){
          var el: any = dom.getElementsByTagName("mat-slide-toggle")[0].getElementsByTagName("INPUT")[0];
          if (!el.checked) {
            el.click();
          }
        }
      }
      //  else {
        this.ship_comp_ctl[key].forEach((element, i) => {
          element.selected = true;
          let checks = dom.getElementsByTagName('mat-checkbox')
          if(checks){
            let check = checks[i];
            let disabled = check && check.classList && check.classList.contains("disabled")
            if (check && !disabled) {
              let el: any = check.getElementsByTagName("INPUT")[0];
              if (el&&!el.checked) {
                el.click();
              }
            }
          }

        });
      // }

    })
    this.groupCheckArray = Array(shipcomp.length).fill(true);
  }
  hideAll() {
    let shipcomp = Object.keys(this.ship_comp_ctl);
    this.groupCheckArray = Array(shipcomp.length).fill(false);
    shipcomp.forEach((key) => {
      let dom = document.getElementsByClassName("side_panel")[0].getElementsByClassName(key)[0];
      let el: any = dom.getElementsByTagName("mat-slide-toggle")[0].getElementsByTagName("INPUT")[0];
      if (el.checked) {
        el.click();
      } else {
        this.ship_comp_ctl[key].forEach((element, i) => {
          element.selected = !true;
          let checks = dom.getElementsByTagName('mat-checkbox')
          let check = checks[i];
          let disabled = check && check.classList && check.classList.contains("disabled")
          if (check && !disabled) {
            let el: any = check.getElementsByTagName("INPUT")[0];
            if (el.checked) {
              el.click();
            }
          }
        });
      }
    })
  }
  componentModelData: any;
  loadingState:any=false;
  groupTransparent(e, key, _i) {
    if(!this.loadingState){
      this.loadingState=true;
      if (this.componentModelData == 'none') {
        this.activeVisible(key);
      }
      setTimeout(() => {
        let shipcomp = Object.keys(this.ship_comp_ctl);
        if (!this.groupCheckArray) {
          this.groupCheckArray = Array(shipcomp.length).fill(true);
        }
        this.groupCheckArray[0] = !this.groupCheckArray[0];
        let dom = document.getElementsByClassName("side_panel")[0].getElementsByClassName(key)[0]
        this.ship_comp_ctl[key].forEach((element, i) => {
          element.selected = e.checked;
          let checks = dom.getElementsByTagName('mat-checkbox')
          let check = checks[i];
          let disabled = check && check.classList && check.classList.contains("disabled")
          if (check && !disabled) {
            let el: any = check.getElementsByTagName("INPUT")[0];
            if (e.checked) {
              if (!el.checked) {
                el.click();
              }
            } else {
              if (el.checked) {
                el.click();
              }
            }
          }
        });
  
        var controlChange = {
          event: 'update-reset-status',
          name: '',
          state: e.checked
        }
        this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
        // this.controlChange.emit({ event: 'update-reset-status', name: "", state: e.checked });
        this.broadcastInfo({src:"sidepanel", event:"showHideIdg",data:this.idgEngineAnim})
        this.componentModelData = _i;
        this.loadingState=false;
      },);
    }

  }
  activeVisible(key){
    this.ship_comp_ctl[key].forEach((element, i) => {
      let info = this.getCtrlData(element.name);
      let state = true;
      info.visible = state;
      var controlChange = {
        event: 'showhidecomp',
        name: element.name,
        state: state,
        solidtrans: info.selected
      }
      this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
      // console.log("showHideComp", controlChange);
    });
  }

  hideComponents(key, _i) {
    if(!this.loadingState){
      this.loadingState = true;
    this.componentModel == 'none'
    this.ship_comp_ctl[key].forEach((element, i) => {
      let info = this.getCtrlData(element.name);
      let state = false;
      info.visible = state;
      var controlChange = {
        event: 'showhidecomp',
        name: element.name,
        state: state,
        solidtrans: info.selected
      }
      this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
      console.log("showHideComp", controlChange);
    });
    this.componentModelData = _i;
    this.loadingState = false;
  }
  }
  groupCheckStatus() {
    var i = 0;
    var shipcomp = Object.keys(this.ship_comp_ctl);
    for (let m in this.ship_comp_ctl) {
      let catg = this.ship_comp_ctl[m];
      let idx = catg.findIndex(e => e.selected == true);
      // if(idx<0){
      //   this.groupCheckArray[i]=false;
      // }else{
      //   this.groupCheckArray[i]=true;
      // }
    }
    //console.log("this.groupCheckArray",this.groupCheckArray);
  }

  checkComponentStatus() {
    var shipcomp = [];
    shipcomp = Object.keys(this.ship_comp_ctl);
    for (let m in this.ship_comp_ctl) {
      let catg = this.ship_comp_ctl[m];
      catg.forEach((element, index) => {
        if (element.focus) {
          this.ship_comp_ctl[m][index]['focus'] = false;
        }
      });
    }
    this.ship_comp_ctl_list = this.ship_comp_ctl;
  }

  onAnimCtl(event, name, i, idx, action = "") {
    let info = this.getCtrlData(name);
    let p = !info.playing;
    var controlChange = {
      event: event,
      name: name,
      state: p
    }
    this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
    // this.controlChange.emit({ event, name, state: p });
  }
  onDemoCtl(event, name, i, idx, action = "", $event = null) {

    // let info = this.getCtrlData(name);
    // let p = !info.playing;
    // this.controlChange.emit({ event, name, state: p });
    let data = this.animateControls[i].subList[idx].data;
    if (action === 'rotate' || action === 'rpm') {
      data.val = $event.value
      var controlChange = {
        event: event,
        name: name,
        state: action
      }
      this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
      // this.controlChange.emit({ event, name, state: action, data });
      return;
    }
    if (name === "CH148") {
      if (idx === 0) {
        this.animateControls[i].subList[1].animateStatus = "stop"
      } else if (idx === 1) {
        this.animateControls[i].subList[0].animateStatus = "stop"
      }
    }
    let info = this.animateControls[i].subList[idx];
    let _action = info.animateStatus === "pause" && action === "play" ? "pause" : action;
    if (name === "Smart_S_Radar" && info.data === 'ship2') {
      _action = action;
    }
    this.animateControls[i].subList[idx].animateStatus = action;
    var controlChanged = {
      event: event,
      name: name,
      state: _action,
      data: info.data
    }
    this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChanged });
    // this.controlChange.emit({ event, name, state: _action, data: info.data });
  }
  onCheckCtl(e, event, name) {
    let info = this.getCtrlData(name);
    info.selected = e.checked;
    var controlChange = {
      event: event,
      name: name,
      state: e.checked,
    }
    this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
    // this.controlChange.emit({ event, name, state: e.checked });
    // console.log("onCheckCtl", controlChange);
    this.groupCheckStatus();
  }
  onColorCtl(e, event, name) {
    var controlChange = {
      event: event,
      name: name,
      state: e,
    }
    this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
    // this.controlChange.emit({ event, name, state: e });
  }
  onLocCtl(event, id, key, name) {
    let info = this.getCtrlData(name);
    let state = !info.focus;
    if (state) {
      this.setPropToAll('focus', false);
    }
    info.focus = state;

    var controlChange = {
      event: event,
      name: name,
      state: state,
      id: id,
      key: key
    }
    this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
    // this.controlChange.emit({ event, name, state, children: info.children });
    // console.log("onLocCtl", controlChange);
  }
  showHideComp(event, name) {
    let info = this.getCtrlData(name);
    let state = !info.visible;
    info.visible = state;
    var controlChange = {
      event: event,
      name: name,
      state: state,
      solidtrans: info.selected
    }
    this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
    // console.log("showHideComp", controlChange);
    // this.controlChange.emit({ event, name, state, "solidtrans": info.selected });
  }

  /* * *
   * child component toggles start 
   * * */
  getChildCtrlData(id) {
    let data = null;
    const found = this.shipChildCompsCntl.find(el => el.id === id);
    if (found) {
      data = found;
    }
    return data;
  }
  groupCheckChildStatus() {
    for (let m in this.ship_comp_ctl) {
      let catg = this.ship_comp_ctl[m];
      let idx = catg.findIndex(e => e.selected == true);
    }
  }
  showHideChildComp(event, name) {
    let info = this.getChildCtrlData(name);
    let state = !info.visible;
    info.visible = state;
    var controlChange = {
      event: event,
      name: name,
      state: state,
      material: info.material,
      transparentStatus: info.selected
    }
    this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
  }
  onCheckChildCompCtl(e, event, name) {
    let info = this.getChildCtrlData(name);
    info.selected = e.checked;
    var controlChange = {
      event: event,
      name: name,
      state: e.checked,
      material: info.material,
    }
    this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
  }
  /* * *
   * child component toggles end 
   * * */

  // onShowAllTag(e) {
  //   // this.multiTagStatus =e.checked
  //   this.showAllTag.emit({state:e.checked});
  // }

  transparentStatus: any = false;
  transparentLabel: any = 'Hide Transparent';
  onTransCtrl(e, event) {
    if (e.checked) {
      this.transparentLabel = 'Show Transparent';
    } else {
      this.transparentLabel = 'Hide Transparent';
    }
    this.transparentStatus = e.checked;
    this.viewmode[this._view] = this.transparentStatus;
    var controlChange = {
      event: event,
      name: null,
      state: e.checked,
    }
    this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
    // this.controlChange.emit({ event, name: null, state: e.checked });
  }
  setPropToAll(prop, value) {
    for (let m in this.ship_comp_ctl) {
      let catg = this.ship_comp_ctl[m];
      catg.forEach((c) => {
        c[prop] = value;
      })
    }
  }
  resetCompCtls() {
    for (let m in this.shipCompsCntls) {
      let catg = this.shipCompsCntls[m];
      catg.forEach((c) => {
        c.focus = false;
        c.opacity = 'solid';
        c.visible = true;
      })
    }
  }
  getCtrlData(id) {
    let data = null;
    for (let m in this.ship_comp_ctl) {
      let catg = this.ship_comp_ctl[m];
      catg.forEach((c) => {
        if (c.id == id) {
          data = c;
        }
      })
    }
    return data;
  }
  getFocusElem() {
    let foc_elem = null;
    for (let m in this.shipCompsCntls) {
      let catg = this.shipCompsCntls[m];
      catg.forEach((c) => {
        if (c.focus) {
          foc_elem = c.id;
        }
      })
    }
    return foc_elem;
  }
  onDemoChange(e) {
    console.log(this.selected_demo);
    this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: this.selected_demo });
    // this.controlChange.emit(this.selected_demo);
  }
  cameraTarget(camera) {
    this.cameraTargetClick.emit(camera);
  }

  settingPanelClose() {
    this.handleLeftControlToggle = false;
    this.settingPanelState.emit(false);
  }

  /* * *
  * on change kha
  * * */
  onChangekhaList(kha) {
    this.selectedKha = kha.split(" ").join("").toLowerCase();
    // console.log("select kha", this.selectedKha);
  }

  chooseExcelFile(e) {
    console.log("event", e);
    if (this.selectedKha) {
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(e.target.files[0].name)[1];
      if (ext == 'xlsx' || ext == 'xls' || ext == 'xlsm' || ext == 'csv') {
        this.selectedFile = e.target;
        let kha = this.selectedKha.split(" ").join("").toLowerCase();
        this.formData.append('file', e.target.files[0]);
        this.formData.append('kha', kha);
        this.selectedFile = e.target.files[0].name;
        // let url = this.configService.project_config.networkUpload;
        let url;
        if (this.selectedKha.includes("menu-")) {
          url = this.configService.project_config.kysmenuUpload;
        } else {
          url = this.configService.project_config.networkUpload;
        }
        this.showBlockLoading = true;
        this.configService.uploadExcelData(url, this.formData, this.excelUploadSuccess);
      } else {
        // this.openDialog.emit("Invalid File");
        alert("Invalid File");
        return;
      }
    } else {
      alert("Please Select Kha option !!");
      return;
    }
  }

  // onFileChange(ev) {
  //   let workBook = null;
  //   let jsonData = null;
  //   const reader = new FileReader();
  //   const file = ev.target.files[0];
  //   reader.onload = (event) => {
  //     const data = reader.result;
  //     workBook = XLSX.read(data, { type: 'binary' });
  //     jsonData = workBook.SheetNames.reduce((initial, name) => {
  //       const sheet = workBook.Sheets[name];
  //       initial[name] = XLSX.utils.sheet_to_json(sheet);
  //       return initial;
  //     }, {});
  //     const dataString = JSON.stringify(jsonData);
  //     document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
  //     this.setDownload(dataString);
  //   }
  //   reader.readAsBinaryString(file);
  // }


  // setDownload(data) {
  //   // this.willDownload = true;
  //   setTimeout(() => {
  //     const el = document.querySelector("#download");
  //     el.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
  //     el.setAttribute("download", 'xlsxtojson.json');
  //   }, 1000)
  // }

  /* * * * *
  * check Json or Not
  * * * * * */
  hasJsonStructure(str) {
    if (typeof str !== 'string') return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === '[object Object]'
        || type === '[object Array]';
    } catch (err) {
      return false;
    }
  }

  /* * *
  * reset both file
  * * */
  resetFile() {
    this.formData = new FormData();
    this.selectedFile = 'No file chosen...';
    let elem: any = document.getElementById("fileupload");
    elem.value = "";
  }

  excelUploadSuccess = ((networkData) => {
    this.showBlockLoading = false;
    let isJson = this.hasJsonStructure(networkData);
    if (isJson) {
      networkData = JSON.parse(networkData);
      console.log("plan response : ", networkData);
      if (networkData['result'] == 'success') {
        console.log("networkData ", networkData['data']);
        if (this.selectedKha == 'menu-demo') {
          var menuData = networkData.data;
          var menuDatas = menuData.menuJson;
          var menu = menuData.menuJson[0].appName
          // this.broadcastInfo({ src: 'sidepanel', event: 'menuListUpdate', data: 'menu-demo'});
          // this.broadcastInfo({ src: 'networkmodel', event: 'nodetree', data: 'dvt' });
          this.broadcastInfo({ src: 'networkmodel', event: 'menuListUpdate', data: menuDatas, node: menu});
          this.broadcastInfo({ src: 'networkmodel', event: 'menuList', data: menuData['menuJson'], node: menu });
        }
        if (this.selectedKha == 'demo') {
          console.log("demo node node",  networkData['data'])
          var netData = networkData['data'];
          var nmCompList = netData.vertices;
          // this.GraphicsManager.selectedNodetree = 'dvt';
          this.broadcastInfo({ src: 'networkmodel', event: 'nodetree', data: 'dvt' });
          this.broadcastInfo({ src: 'sidepanel', event: 'loaduploaddata', data: networkData['data'], key: "" });
          this.broadcastInfo({ src: 'networkmodel', event: 'componentList', data: nmCompList });
          this.broadcastInfo({ src: 'networkmodel', event: 'componentDataList', data: nmCompList });
        }
        // Network Model Mechnical
        if (this.selectedKha == 'menu-mode-mechanical') {
          var menuData = networkData.data;
          var menuDatas = menuData.menuJson;
          var menu = menuData.menuJson[0].appName
          this.broadcastInfo({ src: 'networkmodel', event: 'menuListUpdate', data: menuDatas, node: menu});
          this.broadcastInfo({ src: 'networkmodel', event: 'menuList', data: menuData['menuJson'], node: menu });
        }
        if (this.selectedKha == 'network-mechnical') {
          console.log("demo node node",  networkData['data'])
          var netData = networkData['data'];
          var nmCompList = netData.vertices;
          this.broadcastInfo({ src: 'networkmodel', event: 'nodetree', data: 'mechanical' });
          this.broadcastInfo({ src: 'sidepanel', event: 'loaduploaddata', data: networkData['data'], key: "" });
          this.broadcastInfo({ src: 'networkmodel', event: 'componentList', data: nmCompList });
          this.broadcastInfo({ src: 'networkmodel', event: 'componentDataList', data: nmCompList });
        }
        // Network Model Rqs
        if (this.selectedKha == 'menu-mode-rqs') {
          var menuData = networkData.data;
          var menuDatas = menuData.menuJson;
          var menu = menuData.menuJson[0].appName
          this.broadcastInfo({ src: 'networkmodel', event: 'menuListUpdate', data: menuDatas, node: menu});
          this.broadcastInfo({ src: 'networkmodel', event: 'menuList', data: menuData['menuJson'], node: menu });
        }
        if (this.selectedKha == 'network-rqs') {
          // console.log("demo node node",  networkData['data'])
          var netData = networkData['data'];
          var nmCompList = netData.vertices;
          this.broadcastInfo({ src: 'networkmodel', event: 'nodetree', data: 'rqs' });
          this.broadcastInfo({ src: 'sidepanel', event: 'loaduploaddata', data: networkData['data'], key: "" });
          this.broadcastInfo({ src: 'networkmodel', event: 'componentList', data: nmCompList });
          this.broadcastInfo({ src: 'networkmodel', event: 'componentDataList', data: nmCompList });
        }
        // Network Model rcn martech
        if (this.selectedKha == 'menu-mode-rcn-martech') {
          var menuData = networkData.data;
          var menuDatas = menuData.menuJson;
          var menu = menuData.menuJson[0].appName
          this.broadcastInfo({ src: 'networkmodel', event: 'menuListUpdate', data: menuDatas, node: menu});
          this.broadcastInfo({ src: 'networkmodel', event: 'menuList', data: menuData['menuJson'], node: menu });
        }
        if (this.selectedKha == 'network-rcn-martech') {
          // console.log("demo node node",  networkData['data'])
          var netData = networkData['data'];
          var nmCompList = netData.vertices;
          this.broadcastInfo({ src: 'networkmodel', event: 'nodetree', data: 'rcn-martech' });
          this.broadcastInfo({ src: 'sidepanel', event: 'loaduploaddata', data: networkData['data'], key: "" });
          this.broadcastInfo({ src: 'networkmodel', event: 'componentList', data: nmCompList });
          this.broadcastInfo({ src: 'networkmodel', event: 'componentDataList', data: nmCompList });
        }

        this.resetFile();
        this.broadcastInfo({ src: 'sidepanel', event: 'success', data: true });
        // alert('File Uploaded Successfully !!!');
      } else {
        this.resetFile();
        this.broadcastInfo({ src: 'sidepanel', event: 'unknown', data: true });
        // alert('Unknown File Format !!!');
      }
    } else {
      this.resetFile();
      this.broadcastInfo({ src: 'sidepanel', event: 'unknown', data: true });
      // alert('Unknown File Format !!!');
    }
  })

  // sidepanel nsc
  /* * *
 * method for search
 * * */
  nscSearchValue(e) {
    if (e.key === 'Backspace') {
      setTimeout(() => {
        this.broadcastInfo({ src: 'sidepanel', event: 'nscfilter', data: this.nscSearch, key: "" });
      }, 100)
    } else {
      this.broadcastInfo({ src: 'sidepanel', event: 'nscfilter', data: this.nscSearch, key: "" });
    }
  }

  /* * *
  * method for search clear
  * * */
  nscSearchClear() {
    this.nscSearch = "";
    this.broadcastInfo({ src: 'sidepanel', event: 'nscfilter', data: this.nscSearch, key: "" });
  }

  selectedLevel: any;
  networDataTree: any = []
  listData = [];
  orderStatusList = []
  orderTreeData() {
    const root = [];
    this.networDataTree = [];

    this.networkData.forEach(node => {
      // No parentId means top level
      if (!node.parentID) return root.push(node);
      if (node.orderID != 2) {
        node['expand'] = false;
      }
      // Insert node as child of parent in flat array
      const parentIndex = this.networkData.findIndex(el => el.orderID === node.parentID);
      if (!this.networkData[parentIndex].childList) {
        return this.networkData[parentIndex].childList = [node];
      }
      const found = this.networkData[parentIndex].childList.some(ele => ele.id === node.id);
      if (!found) {
        this.networkData[parentIndex].childList.push(node);
      }
    });
    this.networDataTree = root;
    console.log(root);
  }

  expandCurrentLevel(level) {
    if (this.selectedLevel == level) {
      delete this.selectedLevel;
    } else {
      this.selectedLevel = level;
    }
  }

  getValidIndex(index) {
    index = index.split(" ").join("");
    return index ? true : false;
  }

  getActiveIndex(index) {
    return this.activeIndex.some(el => el === index);
  }

  ActiveIndex(node, i = null) {
    if (!i) {
      i = this.networkData.findIndex(el => el.id == node.id);
    }
    // if (this.appModeDtails == 'idg') {
    //   console.log("nmCompMiniAdd", node)
    //   let idx = this.nmCompMiniList.findIndex(ele => ele.id == node.id);
    //   if (idx == -1) {
    //     this.nmCompMiniList.push(node);
    //     let indx = this.maxList.findIndex(ele => ele.id == node.id);
    //     if (indx > -1) {
    //       this.maxList.splice(indx, 1);
    //       this.broadcastInfo({ src: 'networkmodel', event: 'nmCompMiniAdd', miniData: this.nmCompMiniList, maxData: this.maxList});
    //       this.broadcastInfo({ src: 'sidepanel', event: 'nmCompMiniAdd', miniData: this.nmCompMiniList, maxData: this.maxList});
    //     }
    //   }
    //   // this.nmCompMiniList.push(node);
    //   // this.broadcastInfo({ src: 'networkmodel', event: 'nmCompMiniAdd', miniData:this.nmCompMiniList});

    // } else {
    const found = this.activeIndex.some(el => el === i);
    if (found) {
      let idx = this.activeIndex.findIndex(el => el === i);
      if (idx > -1) {
        this.activeIndex.splice(idx, 1);
        this.broadcastInfo({ src: 'sidepanel', event: 'selectedindex', id: node['id'], key: false });
      }
    } else {
      this.activeIndex.push(i);
      this.broadcastInfo({ src: 'sidepanel', event: 'selectedindex', id: node['id'], key: true });
    }
    // }
  }
  infoPanelToggle(e) {
    if (this.infoPanel == e) {
      delete this.infoPanel;
    } else {
      this.infoPanel = e;
    }
  }
  onNSCTabChanged(tab) {
    this.NSCTab = tab.tab.textLabel;
  }
  getElement(data) {
    if (data != undefined) {
      var elementList = data.split("/");
      if (elementList.length > 0) {
        var ele = '<ul>';
        elementList.forEach(element => {
          var str = element.split(/ +/)[0] == '' ? element.split(/ +/)[1] : element.split(/ +/)[0];
          var matches = str.match(/(\d+)/);
          var style = "";
          if (matches != null) {
            style = "indent"
          }
          if (ele != undefined) {
            ele += '<li id="' + style + '"><p>' + element + '<p></li>';
          } else {
            ele = '<li id="' + style + '"><p>' + element + '<p></li>';
          }
        });
        ele += '</ul>'
        // console.log(ele);
        if (ele != undefined) {
          // return ele;
          return this.html_sanitizer.bypassSecurityTrustHtml(ele);
        }

      } else {
        return "";
      }
    } else {
      return "";
    }

  }

  getScore() {
    let arr = this.quizList.filter(el => el.answerStatus === true);
    let val = arr.length * 100 / this.quizList.length;
    return val;
  }

  getCorrectAnsLen() {
    let arr = this.quizList.filter(el => el.answerStatus === true);
    return arr.length;
  }

  getWrongAnsLen() {
    let arr = this.quizList.filter(el => el.answerStatus === false);
    return arr.length;
  }

  getFocusState() {
    if (this.explodeState) {
      return this.focusMode ? true : true;
    } else {
      if (this.activeModel === 'inexxt') {
        return true;
      } else {
        return this.focusMode ? true : false;
      }

    }
  }
  idgSearchData() {
    var components: any;
    components = this.ship_comp_ctl
    var data = [];
    data = components.Components
    var filterData = [];
    var filterKey = ['name'];

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (filterKey.length > 0) {
          filterKey.forEach((key) => {
            if (typeof data[i][key] === 'string' && typeof this.idgSearch === 'string') {
              if (data[i][key].toLowerCase().indexOf(this.idgSearch.toLowerCase()) > -1) {
                const found = filterData.some(el => el.name === data[i].name);
                if (!found) {
                  filterData.push(data[i]);
                }
              }
            }
          });
        }
      }
    }
    const searchData = Object.assign({}, { Components: filterData });
    this.ship_comp_ctl_list = searchData;
  }

  idgSearchClear() {
    this.idgSearch = "";
    this.ship_comp_ctl_list = this.ship_comp_ctl;
    this.shipChildCompsCntlsList = this.shipChildCompsCntl;
  }

  activeCompList: any = ['base_frame'];
  isActiveComp(name) {
    return this.activeCompList.some(el => el === name);
  }

  onSelectComp(event, name) {
    let state
    const found = this.activeCompList.some(el => el === name);
    if (found) {
      const idx = this.activeCompList.findIndex(el => el === name);
      if (idx > -1) {
        state = false;
        this.activeCompList.splice(idx, 1);
      }
    } else {
      state = true;
      this.activeCompList.push(name);
    }
    var controlChange = {
      event: event,
      name: name,
      state: state,
    }
    this.broadcastInfo({ src: 'sidepanel', event: this._appMode + '-controlChange', data: controlChange });
    // this.controlChange.emit({ event, name, state });
  }

  getAnsStatus(name) {
    if (this.quizList) {
      const correct = this.quizList.some(el => el.answerStatus == true && el.answer == name);
      const worng = this.quizList.some(el => el.answerStatus == false && el.answer == name);
      if (correct) {
        return 'greenText';
      } else if (worng) {
        return 'redText';
      } else {
        return '';
      }
    } else {
      return '';
    }

  }

  onEndQuiz() {
    this.showPanelOpen = false;
    this.handleToggleOpen = false;
    this.showAssemblePanelOpen = false;
    this.handleAssembleToggleOpen = false;
    this.quizHandleToggleOpen = true;
    this.quizPanelOpen = true;
    this.broadcastInfo({ src: 'main', event: 'stop-drag-component', data: "" })
  }

  showEngineAnim(e) {
    this.engineAnim = !this.engineAnim;
    if (this.engineAnim) {
      this.handleToggleOpen = false;
      this.handleDrawerToggleOpen = false;
    } else {
      this.handleToggleOpen = true;
      this.handleDrawerToggleOpen = true;
    }
    this.startAnim = true;
    this.broadcastInfo({ src: "positionTool", event: "drag", key: this.engineAnim, "startAnim": this.startAnim, "dragView": false })
    // this.broadcastInfo({src : "sidepanel", event : "explode" , key : this.engineAnim ,"startAnim" : this.startAnim,"dragView" : false })
    this.broadcastInfo({ src: 'sidepanel', event: 'dragReset', key: this.engineAnim, 'startanim': this.startAnim });
    this.broadcastInfo({ src: 'sidepanel', event: 'engineAnim', key: this.engineAnim });
  }
  idgEngineAnim = false;
  showIDGEngineAnim(e) {
    this.idgEngineAnim = !this.idgEngineAnim;
    if (this.idgEngineAnim) {
      this.handleToggleOpen = false;
      this.handleDrawerToggleOpen = false;
    } else {
      this.handleToggleOpen = true;
      this.handleDrawerToggleOpen = true;
      if(this.idgEngineSmoke){
        this.idgEngineSmoke=false;
        this.broadcastInfo({ src: 'sidepanel', event: 'idg-engineSmoke', key: this.idgEngineSmoke });
      }
    }
    this.startAnim = true;
    this.broadcastInfo({ src: "positionTool", event: "drag", key: this.idgEngineAnim, "startAnim": this.startAnim, "dragView": false })
    // this.broadcastInfo({src : "sidepanel", event : "explode" , key : this.idgEngineAnim ,"startAnim" : this.startAnim,"dragView" : false })
    this.broadcastInfo({ src: 'sidepanel', event: 'idg-dragReset', key: this.idgEngineAnim, 'startanim': this.startAnim });
    this.broadcastInfo({ src: 'sidepanel', event: 'idg-engineAnim', key: this.idgEngineAnim });
  }
  idgEngineSmoke=false;
  showIDGEngineSmoke(e){
    this.idgEngineSmoke = !this.idgEngineSmoke;
    this.broadcastInfo({ src: 'sidepanel', event: 'idg-engineSmoke', key: this.idgEngineSmoke });
  }
  startEngineAnim() {
    this.startAnim = !this.startAnim;
    this.broadcastInfo({ src: "positionTool", event: "drag", key: this.engineAnim, "startAnim": this.startAnim, "dragView": false })
    this.broadcastInfo({ src: 'sidepanel', event: 'startAnimation', key: this.engineAnim, "startAnim": this.startAnim })
  }
  startPathAnim(e) {
    this.pathAnim = !this.pathAnim;
    if (this.pathAnim) {
      this.handleToolsToggleOpen = false;
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'pathAnimMode', key: this.pathAnim });
    this.broadcastInfo({ src: 'sidepanel', event: 'pathAnim', key: this.pathAnim });
    this.broadcastInfo({ src: 'sidepanel', event: 'updateSettigns', data: e.checked });
    if (e.checked == true) {
      this.handleToolsToggleOpen = false;
    } else {
      this.handleToolsToggleOpen = true;
    }
    // console.log("Checked", e.checked);
  }
  pathAnim2
  startPathAnim2(e) {
    this.pathAnim2 = !this.pathAnim2;
    if (this.pathAnim2) {
      this.handleToolsToggleOpen = false;
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'pathAnimMode', key: this.pathAnim2 });
    this.broadcastInfo({ src: 'sidepanel', event: 'pathAnim', key: this.pathAnim2, data: 'demo2' });
    this.broadcastInfo({ src: 'sidepanel', event: 'updateSettigns', data: e.checked });
    if (e.checked == true) {
      this.handleToolsToggleOpen = false;
    } else {
      this.handleToolsToggleOpen = true;
    }
  }
  pathAnim3
  startPathAnim3(e) {
    this.pathAnim3 = !this.pathAnim3;
    if (this.pathAnim3) {
      this.handleToolsToggleOpen = false;
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'pathAnimMode', key: this.pathAnim3 });
    this.broadcastInfo({ src: 'sidepanel', event: 'pathAnim', key: this.pathAnim3, data: 'demo3' });
    this.broadcastInfo({ src: 'sidepanel', event: 'updateSettigns', data: e.checked });
    if (e.checked == true) {
      this.handleToolsToggleOpen = false;
    } else {
      this.handleToolsToggleOpen = true;
    }
  }
  cutMode
  setCutMode(e) {
    this.cutMode = !this.cutMode;
    this.broadcastInfo({ src: 'sidepanel', event: 'cut-mode', key: this.cutMode, data: '' });

  }
  drawMode
  setDrawMode(e) {
    this.drawMode = !this.drawMode;
    this.broadcastInfo({ src: 'sidepanel', event: 'draw-mode', key: this.drawMode, data: '' });

  }
  pauseMode
  setPauseMode(e) {
    this.pauseMode = !this.pauseMode;
    this.broadcastInfo({ src: 'sidepanel', event: 'pause-cut', key: this.pauseMode, data: '' });

  }
  removeMarker() {
    this.broadcastInfo({ src: 'sidepanel', event: 'remove-marker', key: '', data: '' });
  }
  removeCutoff() {
    this.broadcastInfo({ src: 'sidepanel', event: 'remove-cut', key: '', data: '' });
  }
  clearMarkers() {
    this.broadcastInfo({ src: 'sidepanel', event: 'clear-markers', key: '', data: '' });
  }
  clearCutoffs() {
    this.broadcastInfo({ src: 'sidepanel', event: 'clear-cuts', key: '', data: '' });
  }
  applyCut() {
    this.broadcastInfo({ src: 'sidepanel', event: 'apply-cut', key: '', data: '' });
  }
  showlabels=true;
  showHideDeckLabels() {
    this.showlabels = !this.showlabels;
    this.broadcastInfo({ src: 'sidepanel', event: 'showhide-labels', key: this.showlabels, data: '' });
  }
  showruler=true;
  showHideRuler(e=undefined) {
    this.showruler = !this.showruler;
    this.broadcastInfo({ src: 'sidepanel', event: 'showhide-ruler', key: this.showruler, data: '' });
  }
  showFL=true;
  showHideFL(e=undefined) {
    this.showFL = !this.showFL;
    this.broadcastInfo({ src: 'sidepanel', event: 'showhide-fl', key: this.showFL, data: '' });

  }
  //showHideExitRoute
  showER=false;
  showHideExitRoute(e) {
    this.showER = !this.showER;
    this.broadcastInfo({ src: 'sidepanel', event: 'exit-route-on', key: this.showER, data: '' });

  }
  draw_fill_mode=false;
  drawInFillMode(e) {
    this.draw_fill_mode = !this.draw_fill_mode;
    this.broadcastInfo({ src: 'sidepanel', event: 'draw-mode-fill', key: this.draw_fill_mode, data: '' });

  }
  showinfo = false;
  showInfo(e) {
    this.showinfo = !this.showinfo;
    this.broadcastInfo({ src: 'sidepanel', event: 'showinfo', key: this.showinfo });
  }

  animationState = false;
  startRHIBAnim(e) {
    this.showAll();
    this.rhibAnim = !this.rhibAnim;
    this.animationState = this.rhibAnim;
    this.broadcastInfo({ src: 'sidepanel', event: 'rhibAnim', key: this.rhibAnim });
    this.broadcastInfo({ src: 'sidepanel', event: 'disabled-controltools', data: e.checked });
    if (e.checked == true) {
      this.handleToggleOpen = false;
      this.handleInsToggleOpen = false;
      this.handleToolsToggleOpen = false;
    } else {
      this.handleToggleOpen = true;
      this.handleToolsToggleOpen = true;
    }
  }

  startRHIBAnimVer2(e) {
    this.rhibAnimVer2 = !this.rhibAnimVer2;
    this.animationState = this.rhibAnimVer2;
    this.broadcastInfo({ src: 'sidepanel', event: 'rhibAnimVer2', key: this.rhibAnimVer2 });
    this.broadcastInfo({ src: 'sidepanel', event: 'disabled-controltools', data: e.checked });
    if (e.checked == true) {
      this.handleToggleOpen = false;
      this.handleInsToggleOpen = false;
      this.handleToolsToggleOpen = false;
    } else {
      this.handleToggleOpen = true;
      this.handleToolsToggleOpen = true;
    }
  }
  rhibAnimBrnkl=false
  startBrnklAnim(e) {
    this.showAll();
    this.rhibAnim = false;
    this.rhibAnimVer2=false;
    this.animationState = e.checked;
    this.rhibAnimBrnkl=e.checked;
    this.broadcastInfo({ src: 'sidepanel', event: 'rhibAnimBrnkl', key: this.animationState });
    this.broadcastInfo({ src: 'sidepanel', event: 'disabled-controltools', data: this.animationState,key:'brnkl' });
    if (e.checked == true) {
      this.handleToggleOpen = false;
      this.handleInsToggleOpen = false;
      this.handleToolsToggleOpen = false;
    } else {
      this.handleToggleOpen = true;
      this.handleToolsToggleOpen = true;
    }
  }
  toggleEngineSkin(e) {
    this.broadcastInfo({ src: 'sidepanel', event: 'engine-trans-toggle', key: e.checked });
  }
  getPanelwidth(e) {
    let rawElem = document.getElementById(e);
    let width;
    if (e == 'controlPanel') {
      width = rawElem.offsetHeight;
    } else {
      width = rawElem.offsetWidth;
    }

    return width + 'px';
  }

  /* * * * *
  * format label
  * * * * * */
  formatLabel(value: number) {
    return value;
  }

  onChangeMoe(ev) {
    let val = ev.value;
    this.moe = val;
    this.ctrlServ.moe = val;
    console.log("onChangeMoe ", this.moe);
  }

  onLoadModel(e, name) {
    if (!e.checked) {
      e.source.checked = true;
      return;
    }
    if (name === 'inexxt') {
      if (this.transparentStatus) {
        this.onTransCtrl({ checked: !this.transparentStatus }, 'showhidetrans');
        setTimeout(() => {
          this.showAll();
          this.handleToggleOpen = true;
          this.showPanelOpen = true;
        });
      } else {
        this.showAll();
        this.handleToggleOpen = true;
        this.showPanelOpen = true;
      }
    } else {
      this.handleToggleOpen = true;
      this.showPanelOpen = true;
    }
    this.activeModel = name;
    this.broadcastInfo({ src: 'sidepanel', event: 'toggle-view', data: name });
  }

  toggleBuildings(e) {
    this.broadcastInfo({ src: 'sidepanel', event: 'inexxt-building-trans-toggle', key: e.checked });
  }

  selectedMenu: any;
  childMenu: any = [];
  menuActive(pageData) {
    if (pageData.childList.length > 0) {
      if (this.selectedMenu == pageData.key) {
        delete this.selectedMenu;
        this.childMenu = [];
        return;
      }
      this.selectedMenu = pageData.key;
      this.childMenu = pageData.childList;
    } else {
      this.selectedMenu = pageData.key;
      this.openContent(pageData);
    }
  }

  openContent(e) {
    console.log(e)
  }

  selectedComp: any;
  gotoCompartment(s) {
    let show = true;
    // if(this.selectedComp){
    //  let idx = this.shipComp['compList'].findIndex(e=> e['code']==this.selectedComp.code);
    //  if(idx>-1){
    //    this.shipComp['compList'][idx]['selected']=false;
    //  }
    // }
    this.selectedComp = s.code;
    // this.shipComp['compList'].forEach(sys => {
    //   if (sys['code'] == s.code) {
    //     sys.selected = !sys.selected;
    //     show = sys.selected;
    //   }
    // });
    //  eckFliter = this.shipComp['compList'].filter(element =>  == value.toLowerCase());

    var deckValue = s.code.substring(0, 2).toLowerCase().indexOf('0') > -1 ? s.code.substring(0, 2).toLowerCase() : s.code.substring(0, 1).toLowerCase();
    // console.log(s.code.substring(0, 2).toLowerCase(),deckValue)
    let indx = this.deckList.findIndex(e => e == deckValue);
    if (indx > -1) {
      this.broadcastInfo({ src: 'sidepanel', event: 'deckValue', data: deckValue });
      this.broadcastInfo({ src: 'sidepanel', event: 'focusSubSystem', data: 'true', key: s });
    }

    // var subSystemActiveList = this.ctrlServ.showSysComp
    // var subSystemActive = subSystemActiveList[this.selectedKha];
    // if (!subSystemActive) return;
    // console.log("subSystemActive",subSystemActive)
    // subSystemActive.forEach((item, index) => {
    //   if (item == s) {

    //   }
    // })
  }
  sortComp: any;
  sortCompartment(e) {
    this.sortComp = e;
    // this.comps.forEach((element,i) => {
    //   if(element.component=='Compartments'){
    if (this.shipComp['compList'].length > 0) {
      this.shipComp['compList'].sort((a, b) => a[e] < b[e] ? -1 : a[e] > b[e] ? 1 : 0);
    }
    //   }
    // });
  }

  altDialName = "Tilt-X";
  rsDialName = "Tilt-Z";
  ryDialName = "Turn";
  tiltSpeed = "Speed"
  ToggleOpen: boolean = false;
  rotMin = -45;
  rotMax = 90;
  turnMin = -180;
  trunMax = 180;


  // onSpeedOutput(e){
  //   console.log('onSpeedOutput',e);
  //   this.onBoatControlChange.emit({action:"speed",value:e})
  // }

  rhibSpeedCtls = 0.5;
  onChangeRHIBSpeed(ev) {
    // this.onBoatControlChange.emit({ action: "speed", value: e }); 
    let val = ev / 100;
    this.rhibSpeedCtls = val;
    this.ctrlServ.rhibSpeed = val;
  }

  rhibXRotate = 0;
  onChangeRHIBXRot(ev) {
    let val = ev;
    this.rhibXRotate = val;
    this.ctrlServ.rhibXRotate = val;
    if (!this.animationState) {
      let obj = { x: val};
      this.ctrlServ.modifyOrientation('imodel', obj);
    }
  }

  rhibZRotate = -10;
  onChangeRHIBZRot(ev) {
    let val = ev;
    this.rhibZRotate = val;
    this.ctrlServ.rhibZRotate = val;
    if (!this.animationState) {
      let obj = {z: val };
      this.ctrlServ.modifyOrientation('imodel', obj);
    }
  }

  rhibYRotate = 0;
  onChangeRHIBYRot(ev) {
    let val = ev;
    this.rhibYRotate = val;
    this.ctrlServ.rhibYRotate = val;
    if (!this.animationState) {
      let obj = { y: val };
      this.ctrlServ.modifyOrientation('imodel', obj);
    }
  }

  toggleOceanState = false;
  toggleOcean(e) {
    this.toggleOceanState = e.checked;
    this.broadcastInfo({ src: 'sidepanel', event: 'toggleocean', data: e.checked, key: "" });
  }

  hideOceanState = false;
  showHideOcean(e) {
    this.hideOceanState = e.checked;
    this.broadcastInfo({ src: 'sidepanel', event: 'showhideocean', data: e.checked, key: "" });
  }

}
