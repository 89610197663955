
/*******
 * before reference code have "v0.20-tiledGround" this branch
 */
 import { ElementRef, Injectable, NgZone, HostListener } from '@angular/core';
 import { Observable, Subject, BehaviorSubject } from 'rxjs';
 import { HttpClient } from '@angular/common/http';
declare var BABYLON

 @Injectable()
 export class TileGroundService {


   zoom: any = 18;
   zoomDefault: any = 18;
   gridCoords: any = {};
   gridTile: any = [];
   subdivisions: any = {
     'h': 10,
     'w': 10
   };
   mapLatitude: any = 48.4246491;
   mapLongitude: any = -123.3033455;
   xTileBase: any;
   zoomMapLoadStatus: boolean = false;
   yTileBase: any;
   cameras: any;
   loadBuild = []
   mapZoomTile: any = [
     { zoomLevel: 7, tileCount: 127, zoomIn: { lat: -6, long: 6 }, zoomOut: { lat: 2, long: -3 }, shipObjSize: 0.00003, commonObjSize: 1, scaleFactor:1},
     { zoomLevel: 8, tileCount: 255, zoomIn: { lat: -6, long: 6 }, zoomOut: { lat: 2, long: -3 }, shipObjSize: 0.0007, commonObjSize: 1.3, scaleFactor:1.5 },
     { zoomLevel: 9, tileCount: 511, zoomIn: { lat: -7, long: 6 }, zoomOut: { lat: 2, long: -2 }, shipObjSize: 0.0001, commonObjSize: 1.7, scaleFactor:2 },
     { zoomLevel: 10, tileCount: 1023, zoomIn: { lat: -6, long: 5 }, zoomOut: { lat: 2, long: -2 }, shipObjSize: 0.0003, commonObjSize: 2, scaleFactor:2.5 },
     { zoomLevel: 11, tileCount: 2047, zoomIn: { lat: -6, long: 6 }, zoomOut: { lat: 2, long: -2 }, shipObjSize: 0.0007, commonObjSize: 2.3, scaleFactor:3 },
     { zoomLevel: 12, tileCount: 4095, zoomIn: { lat: -7, long: 5 }, zoomOut: { lat: 2, long: -3 }, shipObjSize: 0.001, commonObjSize: 2.7, scaleFactor:3.5 },
     { zoomLevel: 13, tileCount: 8191, zoomIn: { lat: -6, long: 6 }, zoomOut: { lat: 2, long: -3 }, shipObjSize: 0.003, commonObjSize: 3, scaleFactor:4 },
     { zoomLevel: 14, tileCount: 16383, zoomIn: { lat: -6, long: 6 }, zoomOut: { lat: 2, long: -2 }, shipObjSize: 0.007, commonObjSize: 3.3, scaleFactor:4.5 },
     { zoomLevel: 15, tileCount: 32767, zoomIn: { lat: -6, long: 5 }, zoomOut: { lat: 2, long: -2 }, shipObjSize: 0.01, commonObjSize: 3.7, scaleFactor:5 },
     { zoomLevel: 16, tileCount: 65535, zoomIn: { lat: -8, long: 6 }, zoomOut: { lat: 2, long: -2 }, shipObjSize: 0.03, commonObjSize: 4, scaleFactor:5.5 },
     { zoomLevel: 17, tileCount: 131071, zoomIn: { lat: -6, long: 6 }, zoomOut: { lat: 2, long: -2 }, shipObjSize: 0.07, commonObjSize: 4.3, scaleFactor:6 },
     { zoomLevel: 18, tileCount: 262143, zoomIn: { lat: -6, long: 6 }, zoomOut: { lat: 2, long: -2 }, shipObjSize: 0.11, commonObjSize: 4.7, scaleFactor:6.5 }
   ];
   boxArr = []
   tiledGround: any;
   animateInterval: any;
   boreholeLocData: any;
   boreholeMetaData: any;
   public constructor(private http: HttpClient) {
     window['tile_scope']=this;

   }
   /****
    * Get grid center tile cernterlatiitude and longitude
    */
   getGridCenterLatLong() {
     for (var row = 0; row < this.subdivisions.h; row++) {
       for (var col = 0; col < this.subdivisions.w; col++) {
         if (row == this.subdivisions.w / 2 && col == this.subdivisions.w / 2) {
           var xTileBase = this.xTileBase + col;
           var yTileBase = this.yTileBase - row;
           var latBL = this.tile2lat(yTileBase, this.zoom);
           var longBL = this.tile2long(xTileBase, this.zoom);
           var latBR = this.tile2lat(yTileBase, this.zoom);
           var longBR = this.tile2long(xTileBase + 1, this.zoom);
           var latTL = this.tile2lat(yTileBase - 1, this.zoom);
           var longTL = this.tile2long(xTileBase, this.zoom);
           var latTR = this.tile2lat(yTileBase - 1, this.zoom);
           var longTR = this.tile2long(xTileBase + 1, this.zoom);
           var centerGrid = { topLeft: { lat: latTL, long: longTL }, topRight: { lat: latTR, long: longTR }, bottomLeft: { lat: latBL, long: longBL }, bottomRight: { lat: latBR, long: longBR } };
           var longDistance = (centerGrid.bottomLeft.long - centerGrid.bottomRight.long) / 5;
           var latDistance = (centerGrid.bottomLeft.lat - centerGrid.topLeft.lat) / 5;
           var lat = centerGrid.bottomLeft.lat + latDistance;
           var long = centerGrid.bottomLeft.long - longDistance
           return { lat: lat, long: long };
         }
       }
     }
   }

   /****
    * Get row,col tile four corner latiitude and longitude
    */
   getTileCoords(row, col) {
     var xTileBase = this.xTileBase + col;
     var yTileBase = this.yTileBase - row;
     var latBL = this.tile2lat(yTileBase, this.zoom);
     var longBL = this.tile2long(xTileBase, this.zoom);
     var latBR = this.tile2lat(yTileBase, this.zoom);
     var longBR = this.tile2long(xTileBase + 1, this.zoom);
     var latTL = this.tile2lat(yTileBase - 1, this.zoom);
     var longTL = this.tile2long(xTileBase, this.zoom);
     var latTR = this.tile2lat(yTileBase - 1, this.zoom);
     var longTR = this.tile2long(xTileBase + 1, this.zoom);
     if (row == 0 && col == 0) {
       this.latLong2Meter(latBL, longBL, latBR, longBR)
     }
     return { topLeft: { lat: latTL, long: longTL }, topRight: { lat: latTR, long: longTR }, bottomLeft: { lat: latBL, long: longBL }, bottomRight: { lat: latBR, long: longBR } };
   }

   /****
    * Create canvas element from our HTML document
    */
   xmin: any = 0;
   zmin: any = 0;
   xmax: any = 100;
   zmax: any = 100;
   precision: any = {
     "w": 2,
     "h": 2
   };
   cameraPosition: any = { x: 50, y: -20, z: 50 }
   canvas;
   scene;
   dfx
   public createTiledGround(canvas,dfx): void {
    this.dfx=dfx;
     this.canvas = canvas.nativeElement;
     this.scene = dfx.getCamera().getScene();
     this.cameraControl();
     this.updateMaptile();
   }

   /****
    * Get Meter from lattitude and longitude
    */
   tileKM: any;
   tileMeter: any;
   latLong2Meter(lat1, lon1, lat2, lon2) {  // generally used geo measurement function
     var R = 6378.137; // Radius of earth in KM
     var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
     var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
     var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
       Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
       Math.sin(dLon / 2) * Math.sin(dLon / 2);
     var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
     var d = R * c;
     var Meter = d * 1000;
     this.tileMeter = Number((Meter).toFixed(4));
     console.log(this.tileMeter);
     this.tileKM = Number((Meter / 1000).toFixed(1));
     console.log(this.tileKM + " km");
     // return d * 1000; // meters
   }

   /****
    * Lattitude and longitude move center position of the map
    */
   latLongToTileCenter() {
     this.xTileBase = this.long2tile(this.mapLongitude, this.zoomDefault) - 5;
     this.yTileBase = this.lat2tile(this.mapLatitude, this.zoomDefault) + 5;
   }

   /******
    * Map update tile
    */
   updateMaptile() {
     /* if (this.scene.meshes) {
       let idx = this.scene.meshes.findIndex(e => e.id == "Tiled Ground");
       if (idx > -1) {
         this.scene.meshes[idx].dispose();
       }
     } */
     if(this.tiledGround){
      this.tiledGround.dispose();
     }
     this.tiledGround = BABYLON.Mesh.CreateTiledGround("Tiled Ground", this.xmin, this.zmin, this.xmax, this.zmax, this.subdivisions, this.precision, this.scene,true);

     //this.tiledGround.enableEdgesRendering(.95);
     //this.tiledGround.edgesWidth = 3.0;
     //this.tiledGround.edgesColor = this.dfx.color4([0, 0, 0, 1]);
     this.zoomMapLoadStatus = false;
     this.zoom = 18;
     this.zoomDefault = 18;
     this.setCamFocusPt()
     this.latLongToTileCenter();
     this.mapUpdate(this.xTileBase, this.yTileBase);
   }

   /****
    * Camera Control Attachment
    */
   keyDown: boolean = false;
   actionInterval: any;
   cameraControl(): void {
     this.cameras = this.dfx.getCamera();
     var inputMap = {};

     this.scene.onPointerObservable.add((pointerInfo) => {
       switch (pointerInfo.type) {
     case BABYLON.PointerEventTypes.POINTERDOWN:
       this.pointerDown();
       break;
     case BABYLON.PointerEventTypes.POINTERUP:
       this.pointerUp();
       break;
     case BABYLON.PointerEventTypes.POINTERMOVE:
       this.pointerMove();
       break;
       }
   });
   }
   startingPoint;

   getGroundPosition() {
     let scene=this.scene;
         var pickinfo = scene.pick(scene.pointerX, scene.pointerY,  (mesh)=> { return mesh == this.tiledGround; });
         if (pickinfo.hit) {
             return pickinfo.pickedPoint;
         }

         return null;
     }
 pointerDown(){
   this.startingPoint = this.getGroundPosition();
 }
 pointerUp(){
   if (this.startingPoint) {
     this.startingPoint = null;
     return;
 }
 }
 pointerMove(){
   if (!this.startingPoint) {
     return;
 }
 var current = this.getGroundPosition();
 if (!current) {
     return;
 }

 var diff = current.subtract(this.startingPoint);
 if(this.focus.position){
 this.focus.position.subtractInPlace(diff);
 if(Math.abs(diff.z)>=Math.abs(diff.x))
   this.setCamTargPt('z', this.focus.position.z);
 if(Math.abs(diff.x)>Math.abs(diff.z))
   this.setCamTargPt('x', this.focus.position.x);
 }
 this.startingPoint = current;
 }

 setCamTargPt(p,v){
   let fobj=this.cameras;
   let prop='target';
   fobj[prop][p]=v;
 }
 setCamFocusPt(){
   let fobj=this.cameras;
   let prop='target';
   if(fobj)
     this.focus.position=fobj[prop].clone();
     this.focus.ipos=fobj[prop].clone();
 }
 quadKey:any;
    tileXYToQuadKey(xTile, yTile, z) {
     var quadKey = "";
     for (var i = z; i > 0; i--) {
       var digit = 0,
       mask = 1 << (i - 1);
       if ((xTile & mask) != 0) {
         digit++;
       }

       if ((yTile & mask) != 0) {
         digit = digit + 2;
       }
       quadKey += digit;
     } // for i return quadKey;

     return quadKey;
   }
   /********
    * Drag move map tile
    */
   dragEvent: boolean = false;
   xdragStart: any = '';
   ydragStart: any = '';
   xdragEnd: any;
   ydragEnd: any;
   dragTileGround(origin) {
     let x = origin.clientX - this.xdragStart;
     let xValue = x / 80;
     let y = this.ydragStart - origin.clientY;
     let yValue = y / 80;
     if (xValue >= 1) {
       this.compassControl('left')
       this.xdragStart = origin.x
       this.ydragStart = origin.y
     }
     if (xValue <= -1) {
       this.compassControl('right')
       this.xdragStart = origin.x
       this.ydragStart = origin.y
     }
     if (yValue >= 1) {
       this.compassControl('down')
       this.xdragStart = origin.x
       this.ydragStart = origin.y
     }
     if (yValue <= -1) {
       this.compassControl('up')
       this.xdragStart = origin.x
       this.ydragStart = origin.y
     }
   }

   /*******
    *  yTile to lattitude conversion
    */
   tile2lat(y, z) {
     var n = Math.pow(2, z);
     var lat_rad = Math.atan(Math.sinh(Math.PI * (1 - 2 * (y + 1) / n)));
     var latitudeMax = lat_rad * 180 / Math.PI;
     return latitudeMax;
   }

   /*******
    *  xTile to longitude conversion
    */
   tile2long(x, z) {
     return Number((x / Math.pow(2, z) * 360 - 180).toFixed(6));
   }

   /*******
    *  lattitude to yTile  conversion
    */
   lat2tile(lat, zoom) {
     return (Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom)));
   }

   /*******
    *  longitude to xTile conversion
    */
   long2tile(lon, zoom) {
     return (Math.floor((lon + 180) / 360 * Math.pow(2, zoom)));
   }

   /*******
    * Find lattitude and longitude location
    */
   findLocation(zoom, lat, long) {
     this.zoomMapLoadStatus = true;
     this.zoomMapLoadStatus = false;
     this.mapLatitude = lat;
     this.mapLongitude = long;
     this.zoom = zoom;
     this.zoomDefault = zoom;
     this.latLongToTileCenter()
     this.mapUpdate(this.xTileBase, this.yTileBase);
   }
   // showMapType:any = 'openStreet';
   // showMapType:any = 'google';
   showMapType:any = 'bing';

   mapType(type){
     this.showMapType = type;
     this.mapUpdate(this.xTileBase, this.yTileBase);
   }
   /*******
    * Map tile update
    */
   public mapUpdate(xTileBase, yTileBase): void {
     this.clearCube();
     // Create Multi Material
     var multimat = this.dfx.getMultiMaterial("multi");
     var i = 0;
     this.gridTile = [];
     for (var row = 0; row < this.subdivisions.h; row++) {
       for (var col = 0; col < this.subdivisions.w; col++) {
         if (xTileBase + col >= 0 && yTileBase - row >= 0) {
           var material = this.dfx.getStandardMaterial(
             "material" + row + "-" + col
           );
           var long = this.tile2long((xTileBase + col), this.zoom);
           var lat = this.tile2lat((yTileBase - row), this.zoom);
           if (col == 0 && row == 0) {
             this.gridCoords['latStart'] = lat;
             this.gridCoords['longStart'] = long;
           }
           if(this.showMapType == 'openStreet'){
             material.diffuseTexture =this.dfx.getTexture(
                       "http://b.tile.openstreetmap.org/" + this.zoom + "/" + (xTileBase + col) + "/" + (yTileBase - row) + ".png"
                     );
           }
           if(this.showMapType == 'google'){
               material.diffuseTexture = this.dfx.getTexture(
             "https://mt1.google.com/vt/lyrs=y&x="+(xTileBase + col)+"&y="+ (yTileBase - row)+"&z="+this.zoom
           );
           }
           if(this.showMapType == 'bing'){
             var quadKey = this.tileXYToQuadKey(xTileBase + col,yTileBase - row,this.zoomDefault)
             material.diffuseTexture =this.dfx.getTexture(
               "http://ecn.t0.tiles.virtualearth.net/tiles/h"+quadKey+".jpeg?g=9295&mkt=en-US"
             );
         }
           // var quadKey = this.TileXYToQuadKey(xTileBase + col,yTileBase - row,i)
           if (col == 9 && row == 9) {
             var xTileBase1 = xTileBase + col;
             var yTileBase1 = yTileBase - row;
             var latTR = this.tile2lat(yTileBase1 - 1, this.zoom);
             var longTR = this.tile2long(xTileBase1 + 1, this.zoom);
             this.gridCoords['latEnd'] = latTR;
             this.gridCoords['longEnd'] = longTR;
           }
           var gridTile = { 'tileCorner': this.getTileCoords(row, col), 'tileNo': i += 1 }
           this.gridTile.push(gridTile);

         }
         material.diffuseTexture.wrapU = BABYLON.Texture.CUBIC_MODE;
         material.diffuseTexture.wrapV = BABYLON.Texture.CUBIC_MODE;
         material.specularColor = this.dfx.color3([0, 0, 0]);
         material.backFaceCulling = false;
         multimat.subMaterials.push(material);
       }
     }
     // Needed variables to set subMeshes
     var verticesCount = this.tiledGround.getTotalVertices();
     var tileIndicesLength = this.tiledGround.getIndices().length / (this.subdivisions.w * this.subdivisions.h);
     // Set subMeshes of the tiled ground
     this.tiledGround.subMeshes = [];
       // Part 3 : Apply the multi material
     // Define multimat as material of the tiled ground
     this.tiledGround.material = multimat;
     this.subMesh(verticesCount, tileIndicesLength, tileIndicesLength);
     //this.updateTilesElevationData();
     // this.hightMapObjectPosition();
     // setTimeout(() => {
     //   this.hightMapObjectPosition();
     // }, 3000);

   }
   subMesh(verticesCount, tileIndice, tileIndicesL) {
     var index = 0;
     var base = 0;
     for (var row = 0; row < this.subdivisions.h; row++) {
       for (var col = 0; col < this.subdivisions.w; col++) {
         var submesh = new BABYLON.SubMesh(
           index++, 0, verticesCount, base, tileIndice, this.tiledGround
         );
         this.tiledGround.subMeshes.push(submesh);
         base += tileIndicesL;
       }
     }
   }
   /*****
    * Object animation
    */
   startAnimation(obj, delay) {
     clearInterval(this.animateInterval);
     var i = 0;
     this.animateInterval = setInterval(() => {
       if (obj.length - 1 >= i) {
         this.loadanimate(obj[i]);
         i += 1;
       } else {
         i = 0;
         this.startAnimation(obj, delay);
       }

     }, delay);
   }

   /*****
    * Load object animation
    */
   loadanimate(ele) {
     ele.forEach(element => {
       var objId = element.id;
       var idName = element.id.split('_')[0];
       if (idName == 'iceberg') {
         element = { "id": element.id, "color": 'orange', "lat": element.place.lat, "long": element.place.long,size:element.size }
       }
       if (element.lat >= this.gridCoords['latStart'] && element.lat <= this.gridCoords['latEnd'] && element.long >= this.gridCoords['longStart'] && element.long <= this.gridCoords['longEnd']) {
         if (idName == 'iceberg' || idName == 'ship') {
           this.objectTileFind(element);
         }
       }
     });
   }

   /*****
    * Map picked get lattitude and longitude
    */
   pickElemObj = [];
   pickLocation(pickObj) {
     if(pickObj.pickInfo.pickedPoint != null){
       var pickEvent = pickObj.pickInfo.pickedPoint;
       var xR = pickEvent.x - this.xmin;
       var yR = pickEvent.z - this.zmin;
       var xRvalue = Math.floor(pickEvent.x / 10);
       var yRvalue = Math.floor(pickEvent.z / 10);
       var yRange = yRvalue * 10;
       var tileIndex = xRvalue + yRange;
       if(tileIndex != undefined){
         var tileWidth = Math.abs((this.gridTile[tileIndex].tileCorner.bottomLeft.long - this.gridTile[tileIndex].tileCorner.bottomRight.long) * 100);
         var tileHeight = Math.abs((this.gridTile[tileIndex].tileCorner.bottomLeft.lat - this.gridTile[tileIndex].tileCorner.topLeft.lat) * 1000);
         var lati = this.tile2lat(this.yTileBase, this.zoom)
         var longi = this.tile2long(this.xTileBase, this.zoom)
         var yPosition = yR * tileHeight;
         var yaxis = yPosition / 10000;
         var xPosition = xR * tileWidth;
         var xaxis = xPosition / 1000;
         var lat = Number((yaxis + lati));
         var long = Number((xaxis + longi));
         if (pickObj.event.which === 3) {
           let xaxis = this.cameraPosition.x / 10;
           let zaxis = this.cameraPosition.z / 10;
           this.xTileBase = this.long2tile(long, this.zoomDefault) - xaxis
           this.yTileBase = this.lat2tile(lat, this.zoomDefault) + zaxis
           this.mapUpdate(this.xTileBase, this.yTileBase);
           return;
         }
         if (this.pickLocationMarker) {
           if (this.pickElemObj.length > 0) {
             this.pickElemObj[0].mesh.isVisible = true;
             this.pickElemObj[0].mesh.position = this.dfx.vector3([pickEvent.x, 0.5, pickEvent.z]);
             var idx = this.boxArr.findIndex(e => e.elementId == 'pickLocation');

             this.pickElemObj[0].lat = lat.toFixed(7);
             this.pickElemObj[0].long = long.toFixed(7)
           } else {
             // var box = MeshBuilder.CreateCylinder("pickLocation", { diameterTop: 0, height: 0.5, tessellation: 96, diameterBottom: 0.5 }, this.scene);
             var box = BABYLON.MeshBuilder.CreatePlane("pickLocation", {}, this.scene);
             box.position = this.dfx.vector3([pickEvent.x, 0.5, pickEvent.z]);;//(pickEvent.x, 0.5, pickEvent.z);
             box.material = this.getMaterial('mapmarker');
             this.pickElemObj.push({ id: "pickLocation", mesh: box, lat: lat.toFixed(7), long: long.toFixed(7) });
             this.boxArr.push({ posId: "pickLocation", elementId: "pickLocation", mesh: box, size:10 });

             this.loadBuild.push({ 'id': "pickLocation", "color": "mapmarker", 'lat': lat, 'long': long,size:10 })
           }
           //this.pickEvent.next(this.pickElemObj);
         }
       }
     }
   }

   /*****
    * Map picked panel clear
    */
   pickedTagClear() {
     if (this.pickElemObj.length > 0) {
       this.pickElemObj[0].mesh.dispose();
       let idx = this.boxArr.findIndex(e => e.posId == "pickLocation");
       if (idx > -1) {
         this.boxArr.splice(idx, 1)
       }
       let index = this.loadBuild.findIndex(e => e.id == "pickLocation");
       if (index > -1) {
         this.loadBuild.splice(index, 1)
       }
     }
     this.pickElemObj = [];
   }

   /****
    * Loaction marker status
    */
   pickLocationMarker: any = false;
   locationMarker(e) {
     this.pickLocationMarker = e;
   }

   /****
    * Map return object scale factor
    */
   tilesize: any = 10;
   scaleFactorValue: any;
   scaleFactor(size) {
     //console.log(this.tileMeter, size)
     var tileBase = this.tilesize / this.tileMeter;
     this.scaleFactorValue=tileBase;
     var scale = size * tileBase;
     //console.log("ScaleFactor", scale, tileBase);
     return scale;
   }

   /*******
    * Inside gird building place lattitude and longitude position
    */
   objectTileFind(e) {
     var objId = e.id;
     var idName = e.id.split('_');
     this.gridTile.forEach(element => {
       if (e.lat >= element.tileCorner.bottomLeft.lat && e.long >= element.tileCorner.bottomLeft.long && e.lat <= element.tileCorner.topRight.lat && e.long <= element.tileCorner.topRight.long) {
         var tileWidth = Math.abs((element.tileCorner.bottomLeft.long - element.tileCorner.bottomRight.long) * 100);
         var tileHeight = Math.abs((element.tileCorner.bottomLeft.lat - element.tileCorner.topLeft.lat) * 1000);
         var tileSeries = element.tileNo / 10;
         var id = objId + "_" + e.lat + "_" + e.long;
         var lati = this.tile2lat(this.yTileBase, this.zoom)
         var longi = this.tile2long(this.xTileBase, this.zoom)
         var yaxis = e.lat - lati;
         var xaxis = e.long - longi;
         var xPos = (xaxis * 1000) / tileWidth;
         var ySeries = (yaxis * 10000) / tileHeight;
         var yPos = ySeries - tileSeries;
         var xPosition = xPos + this.xmin;
         var yPosition;

           yPosition = yPos + this.zmin;

         var objectSize;
         if (this.boxArr.length > 0) {
           var idx = this.boxArr.findIndex(e => e.elementId == objId);
           console.log(idx,objId)
           if (idx > -1) {
             this.boxArr[idx].mesh.isVisible = true;
             objectSize = this.scaleFactor(e.size);
             this.boxArr[idx].mesh.scaling = new BABYLON.Vector3(objectSize, objectSize, objectSize);

               if (idName[0] == 'whales' || idName[0] == 'cube') {
                 this.boxArr[idx].mesh.position = this.dfx.vector3(xPosition, objectSize / 10, yPosition);
               } else {
                 this.boxArr[idx].mesh.position = this.dfx.vector3(xPosition, 0.5, yPosition);

               }

           }
         }
       }
     });
   }

   /****
    * Get Material
    */
   materialList: any = ["black", "red", "orange", "grey", "green", "light grey", "dark grey", "greyBluish", "green", "yellow", "light yellow", "brown", "white"]
   getMaterial(color) {
     var Material
     if (color == 'red') {
       Material = this.dfx.getStandardMaterial('redMat');
       Material.diffuseColor = this.dfx.color3(255, 0, 0);
     }
     if(color == 'transparent'){
       Material = this.dfx.getStandardMaterial('transparentMat');
       Material.alpha = 0.3;
     }
     if (color == 'orange') {
       Material = this.dfx.getStandardMaterial('orangeMat');
       Material.diffuseColor = this.dfx.color3(255, 165, 0);
     }
     if (color == 'grey') {
       Material = this.dfx.getStandardMaterial('greyMat');
       Material.diffuseColor = this.dfx.color3(192, 192, 192);
     }
     if (color == 'light grey') {
       Material = this.dfx.getStandardMaterial('lightGreyMat');
       Material.diffuseColor = this.dfx.color3(240, 240, 240);
     }
     if (color == 'dark grey') {
       Material = this.dfx.getStandardMaterial('darkGreyMat');
       Material.diffuseColor = this.dfx.color3(38, 38, 38);
     }
     if (color == 'greyBluish') {
       Material = this.dfx.getStandardMaterial('greyBluishMat', this.scene);
       Material.diffuseColor = this.dfx.color3(104, 118, 129);
     }
     if (color == 'green') {
       Material = this.dfx.getStandardMaterial('greenMat');
       Material.diffuseColor = this.dfx.color3(0, 255, 0);
     }
     if (color == 'yellow') {
       Material = this.dfx.getStandardMaterial('yellowMat');
       Material.diffuseColor = this.dfx.color3(255, 255, 0);
     }
     if (color == 'light yellow') {
       Material = this.dfx.getStandardMaterial('lightYellowMat');
       Material.diffuseColor = this.dfx.color3(255, 255, 102);
     }
     if (color == 'brown') {
       Material = this.dfx.getStandardMaterial('brownMat');
       Material.diffuseColor = this.dfx.color3(165, 42, 42);
     }
     if (color == 'white') {
       Material = this.dfx.getStandardMaterial('whiteMat');
       Material.diffuseColor = this.dfx.color3(255, 255, 255);
     }
     if (color == 'black') {
       Material = this.dfx.getStandardMaterial('blackMat');
       Material.diffuseColor = this.dfx.color3(0, 0, 0);
     }
     return Material
   }

   /*******
    * Inside gird building find
    */
   loadBuildObject() {
     this.loadBuild.forEach(element => {
       var id = element.id.split('_');
       if (element.lat >= this.gridCoords['latStart'] && element.lat <= this.gridCoords['latEnd'] && element.long >= this.gridCoords['longStart'] && element.long <= this.gridCoords['longEnd']) {
         this.objectTileFind(element);
       }
     });
   }


   /*******
    * Object visible off
    */
   clearCube() {
     if (this.boxArr.length > 0) {
       this.boxArr.forEach(element => {
         element.mesh.isVisible = false;
       });
     }
     console.log(this.boxArr, this.loadBuild);
   }

   /*******
   * Map zoom control
   */
   zoomControl(e) {
     if (e == '-') {
       if (this.zoom > 7) {
         this.zoom -= 1;
       }
     }
     if (e == '+') {
       if (this.zoom < 18) {
         this.zoom += 1;
       }
     }
     if (this.zoom > 6 && this.zoom < 19) {
       if (this.zoomDefault != this.zoom) {
         this.zoomDefault = this.zoom;
         this.latLongToTileCenter();

         this.mapUpdate(this.xTileBase, this.yTileBase);
       }

     }
   }

   /****
    * Map load home position
    */
   focus={position:null,ipos:null}

   /****
    * Navigate map
    */
   dragControl:boolean=true;
   dragAction(e) {
     if (e) {
       this.dragControl= true;
       this.cameras.detachControl(this.canvas, false);
     } else {
       this.dragControl= false;
       this.cameras.attachControl(this.canvas, false);
     }
   }
   cameraView(status){
     console.log("cameraview",status);
     if(status){
       // this.cameras.setTarget(this.dfx.vector3(50, 0, 50))
       // this.cameras.lockedTarget = this.dfx.vector3(50, 0, 50);
       this.cameras.setPosition(this.dfx.vector3(50, 15, -10));

     }else{
       // this.cameras.setTarget(this.dfx.vector3(50, -20, 50))
       // this.cameras.lockedTarget = this.dfx.vector3(50, -20, 50);
       this.cameras.setPosition(this.dfx.vector3(50, -10, -40));

     }
     //this.setCamFocusPt()

   }
   compassControl(e) {
     if (e == 'right') { //if(evt.sourceEvent.key=='ArrowRight'){
       this.xTileBase += 1
     }
     if (e == 'left') {// if(evt.sourceEvent.key=='ArrowLeft'){
       this.xTileBase -= 1
     }
     if (e == 'up') {//if(evt.sourceEvent.key=='ArrowUp'){
       this.yTileBase -= 1
     }
     if (e == 'down') {//if(evt.sourceEvent.key=='ArrowDown'){
       this.yTileBase += 1
     }
     var centerLatLong: any = this.getGridCenterLatLong();;
     this.mapLatitude = centerLatLong.lat;
     this.mapLongitude = centerLatLong.long;
     this.mapUpdate(this.xTileBase, this.yTileBase);
   }
   @HostListener('contextmenu', ['$event'])
   onRightClick(event) {
     event.preventDefault();
   }
 // _bingMapsKey='AiOz4VHxxb2ZRC59vOCYfgj0TdbpEYHBq7TurP_FXh3t6dno6qK6BWoZSVxrdVS0';
 _bingMapsKey='Av0Ju7WeKAcWUb55bs9hfYovNfgiO_1r77b4eZK_ZOkD8e9Rv4PBaFvjXr8lCIRc';
 _meanElevation=null;
 objectUpdateCode:any=0;
   getElevations(r,c){
     // this._meanElevation = null;
     this.scaleFactor(1);
     var query_arr = [];
     var query
     var pixelXY = this.getTileCoords(r,c);

     var latLongsToQuery = [];
     var subdivisions = this.precision;
     let _tileSize={x:pixelXY.bottomRight.long-pixelXY.bottomLeft.long,
                     y:pixelXY.topLeft.lat-pixelXY.bottomLeft.lat
                   };
     for (var row = 0; row <= subdivisions.w; row++) {
         for (var col = 0; col <= subdivisions.h; col++) {
             var long = pixelXY.bottomLeft.long + (col * _tileSize.x) / subdivisions.w ;
             var lat = pixelXY.bottomLeft.lat + (row * _tileSize.y) / subdivisions.h;

             query_arr.push([lat,long]);
         }
     }

     /* latLongsToQuery.forEach((latLong)=> {
         query_arr.push([latLong.latitude, latLong.longitude]) ;
     }); */

     query=this.encodePoints(query_arr);
     // console.log("query","http://dev.virtualearth.net/REST/v1/Elevation/List?jsonp=JSONP_CALLBACK&points="+query+"&key="+this._bingMapsKey)
     let url="http://dev.virtualearth.net/REST/v1/Elevation/List?jsonp=JSONP_CALLBACK&points="+query+"&key="+this._bingMapsKey;
     this.http.jsonp(url, 'callback')
     .pipe(
       /* catchError(this.handleError('jsonpTest', 'ERROR')), */
     ).subscribe((data:any) => {
                 if (data != null &&
                   data.resourceSets &&
                   data.resourceSets.length > 0 &&
                   data.resourceSets[0].resources &&
                   data.resourceSets[0].resources.length > 0 &&
                   data.resourceSets[0].resources[0].elevations) {
                     this.objectUpdateCode++;

                     var elevations = [];
                     for (var j = 0; j < data.resourceSets[0].resources[0].elevations.length ; j++) {
                         var elevation = data.resourceSets[0].resources[0].elevations[j];

                         elevation = elevation;
                         elevations.push(elevation);

                         if (this._meanElevation == null)
                             this._meanElevation = (elevations[0]);
                     }
 this.updateTileElevation(elevations,r,c);
 this.objectUpdateCode =1;
                 }

                 //console.log("elevation",data,elevations,this._meanElevation, this.objectUpdateCode)


     });
     // setTimeout(() => {
     //   this.hightMapObjectPosition();
     // }, 3000);
     setTimeout(() => {
       if(this.objectUpdateCode == 1){
         // this.hightMapObjectPosition();
         if (!this.zoomMapLoadStatus) {

           this.loadBuildObject();
         }
       }
     }, 10000);
   }
   handleError(p1,p2){
     console.log(p1,p2);
   }
   updateTilesElevationData(){
     console.log("updateTilesElevationData")
     this._meanElevation =null;
     this.scaleFactor(1);
     let scope=this;
     for (var row = 0; row < scope.subdivisions.h; row++) {
       for (var col = 0; col < scope.subdivisions.w; col++) {
         scope.getElevations(row,col)
       }
     }
   }
   updateTileElevation(elev,r,c){
     let idx=r*(this.subdivisions.h)+c;
     let sub=this.subdivisions.h-1;
     let tp=(this.precision.w+1)*(this.precision.h+1)*3
     let s=idx*tp;
     let pos=this.tiledGround.getVerticesData("position");
     let p=0;
     let l=s+tp;
     let sf =1
     for(;s<l;s+=3){
       let i=s+1;
       pos[i]=(elev[p]-this._meanElevation)*this.scaleFactorValue*sf;
       p++
     }
     this.tiledGround.updateVerticesData("position",pos);

   }
   encodePoints(points) {
     var latitude = 0;
     var longitude = 0;
     var result = [];
     var l;

     for (var point in points ) {

         // step 2
         var newLatitude = Math.round(points[point][0] * 100000);
         var newLongitude = Math.round(points[point][1] * 100000);

         // step 3
         var dy = newLatitude - latitude;
         var dx = newLongitude - longitude;
         latitude = newLatitude;
         longitude = newLongitude;

         // step 4 and 5
         dy = (dy << 1) ^ (dy >> 31);
         dx = (dx << 1) ^ (dx >> 31);

         // step 6
         var index = ((dy + dx) * (dy + dx + 1) / 2) + dy;

         while (index > 0) {

             // step 7
             var rem = index & 31;
             index = (index - rem) / 32;

             // step 8
             if (index > 0) rem += 32;

             // step 9
             result.push("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-"[rem]);
         }
     }

     // step 10
     return result.join("");
 }
 showHideTiledGround(boo=true){
  this.tiledGround.isVisible=boo
 }
   }
