import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppinputComponent } from './appinput';
import { HttpModule,JsonpModule } from '@angular/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS,HttpClientJsonpModule } from '@angular/common/http';
import { DatauxviewModule } from 'datauxview';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
//
import { RadialSlider } from './components/radial-slider/radial-slider.component'
import { SpeedSlider } from './components/speed-slider/speed-slider.component'
import { AltiSlider } from './components/alti-slider/alti-slider.component'
import { LineSlider } from './components/line-slider/line-slider.component'
//services
//providers
import { ConfigService } from './services/config.service';
import { ControllerService } from './services/controller.service';
import { CommunicationService } from './services/communication.service';
import { TagService } from './services/tag.service';
import { AnimatorService } from './services/animator.service';
import {TileGroundService} from './services/tiling/tileground.service'
//components
import { RHIB } from './rhib/rhib.component';
import { LoaderScreenComponent } from './loader-screen/loader-screen.component';
import { SidepanelComponent } from './sidepanel/sidepanel.component';
import { PositiontoolsComponent } from './positiontools/positiontools.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/', '-lang.json');
}
@NgModule({
  declarations: [
    AppComponent,
    AppinputComponent,
    RHIB,
    LoaderScreenComponent,
    SidepanelComponent,
    PositiontoolsComponent,
    RadialSlider,
    SpeedSlider,
    AltiSlider,
    LineSlider,
    
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    HttpModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DatauxviewModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatSliderModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonToggleModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatMenuModule
  ],
  entryComponents: [
  ],
  exports: [
    BrowserModule,
    HttpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,  
    MatInputModule  
  ],
  providers: [
    ConfigService,
    ControllerService,
    CommunicationService,
    TagService,
    AnimatorService,
    TileGroundService,
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
