import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';

@Component({
  selector: 'line-slider',
  templateUrl: './line-slider.component.html',
  styleUrls: ['./line-slider.component.scss']
})
export class LineSlider implements OnInit {
  raf = null;
  mdown = false;
  mPos = { x: 0, y: 0 }
  elementPosition = { x: 0, y: 0 }
  radius = 36

  knobRadius = 10
  maxDiff = 40
  constraint = 180
  target = 0
  centerX = 0
  centerY = 0
  ctx;
  circleOffset;
  x = 0;
  y = 0;
  $circle;
  $innerCircle;
  $knob;
  $progress;
  canvasSize;
  title = "";
  compInited = false;
  @ViewChild('circle', { static: true }) public circle: any;
  @ViewChild('innercircle', { static: true }) public innerCircle: any;
  @ViewChild('knob', { static: true }) public knob: any;
  @ViewChild('progress', { static: true }) public progress: any;
  //slidercont
  @ViewChild('slidercont', { static: true }) public slidercont: any;
  //
  @Output() rsOutput: EventEmitter<any> = new EventEmitter<any>();
  //
  @Input() default
  @Input() min
  @Input() max
  constructor() {

  }
  @Input() set initcomp(v: any) {
    if (v) {
      this.initSlider();
      this.compInited = true;
    }
  }
  id
  @Input() set name(v: any) {
    if (v) {
      this.title = v;
      this.id = v
    }
  }
  initSlider() {
    this.$circle = this.circle.nativeElement;
    this.$innerCircle = this.innerCircle.nativeElement;
    this.$knob = this.knob.nativeElement;
    this.$progress = this.progress.nativeElement;
    this.ctx = this.$progress.getContext('2d');
    let rect = this.$circle.getBoundingClientRect()
    this.circleOffset = {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    };
    this.elementPosition = {
      x: this.circleOffset.left,
      y: this.circleOffset.top
    }
    let prect = this.$progress.getBoundingClientRect()
    this.centerX = prect.width / 2;
    this.centerY = prect.height / 2;
    this.canvasSize = prect.width;
    let bg = this.getBackground();
    let c = this.slidercont.nativeElement;
    c.style.backgroundImage = "url(" + bg + ")";
    //c.style.backgroundRepeat="no-repeat";
    //c.style.backgroundPosition="center";
    this.$innerCircle.style.backgroundImage = "url(" + bg + ")";
    //this.$innerCircle.style.backgroundRepeat="no-repeat";
    if (!this.compInited) {
      let m = this.min || 0;
      let mx = this.max || 100;
      this.target = (this.default - m) * 180 / mx;
      this.roundTarg = this.default;
    }
    this.updateSlider();
  }
  ngAfterViewInit() {
    console.log("initview line slider")
    const para = document.getElementById('knob' + this.id);
    console.log('Pointer down event', para);
    // para.addEventListener('pointerdown', (event) => {
    //   console.log('Pointer down event');
    // });
    para.addEventListener("touchstart", (event) => {
      console.log("Here a touchstart event is triggered");
      this.mdown = true;
    });
    para.addEventListener("touchmove", (event) => {
      console.log("Here a touchmove event is triggered");
      if (this.mdown) {
        this.setMousePosition(event);
      }
    });
    para.addEventListener("touchend", (event) => {
      console.log("Here a touchend event is triggered");
      this.mdown = false;
    });
  }
  ngOnInit() {
    console.log("init alti slider");

  }
  // Callbacks
  @HostListener('mousedown', ['$event'])
  onMouseDown(event) {
    if (event.target.classList.contains('knob'))
      this.mdown = true;
  }
  // @HostListener('pointer', ['$event'])
  // onMouseDownpointer(event) {
  //   if(event.target.classList.contains('knob'))
  //     this.mdown = true;
  // }
  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event) {
    this.mdown = false;
  }
  @HostListener('mousemove', ['$event'])
  onMouseMove(event) {
    if (this.mdown) {
      this.setMousePosition(event);
    }
  }

  roundTarg = 0;
  setMousePosition(event) {
    var pagex = event.pageX ? event.pageX : event.changedTouches[0]["pageX"];
    var pagey = event.pageY ? event.pageY : event.changedTouches[0]["pageY"];
    this.mPos = {
      x: pagex - this.elementPosition.x,
      y: pagey - this.elementPosition.y
    };

    let atan = Math.atan2(this.mPos.x - this.radius, this.mPos.y - this.radius);
    let target = -atan / (Math.PI / 180) + 180;

    let diff = Math.abs(target - this.target);
    let min = this.min || 0;
    let max = this.max || 100;
    if (target <= this.constraint) {
      this.target = (target);
      this.roundTarg = Math.round((target * max / 180) + min);
      this.rsOutput.emit(this.roundTarg);
    }
  }
  getBackground() {

    let steps = 60;
    let dividerEvery = 15;
    this.$progress.height = this.canvasSize;
    this.$progress.width = this.canvasSize;

    this.ctx.save();
    this.ctx.translate(this.centerX, this.centerY);
    this.ctx.rotate(-90 * Math.PI / 180);
    for (let i = 0, end = steps; i <= end / 2; i++) {
      this.ctx.beginPath();


      if (i % dividerEvery === 0) {
        this.ctx.lineWidth = 2;
        this.ctx.moveTo(50, 0);
        this.ctx.lineTo(26, 0);
        this.ctx.strokeStyle = "#bae5f3";
      } else {
        this.ctx.lineWidth = 1;
        this.ctx.lineTo(45, 0);
        this.ctx.lineTo(25, 0);
        this.ctx.strokeStyle = "#0083ad";
      }

      this.ctx.stroke();
      this.ctx.rotate(Math.PI * 2 / steps);
    }

    this.ctx.restore();

    let img = this.$progress.toDataURL();
    this.ctx.clearRect(0, 0, this.canvasSize, this.canvasSize);

    return img;
  }
  drawArc() {
    this.$progress.width = this.canvasSize;
    this.$progress.height = this.canvasSize;
    this.ctx.save();

    this.ctx.translate(this.centerX, this.centerY - this.radius);
    this.ctx.rotate(-90 * Math.PI / 180);
    this.ctx.strokeStyle = "#ff8c00";//"#0083ad"

    this.ctx.beginPath();
    this.ctx.lineWidth = 8;
    this.ctx.arc(0 - this.radius + 1, -1, this.radius - 1, 0, this.target * Math.PI / 180, false);

    this.ctx.stroke();
    this.ctx.restore();
  }
  setPosition() {
    this.x = Math.round(this.radius * Math.sin(this.target * Math.PI / 180)) + this.radius - this.knobRadius + 4;
    this.y = Math.round(this.radius * -Math.cos(this.target * Math.PI / 180)) + this.radius - this.knobRadius + 4;
    this.$knob.style.left = this.x + "px";
    this.$knob.style.top = this.y + "px";
  }
  updateSlider() {
    let anim = () => {
      this.setPosition();
      this.drawArc();
      this.raf = requestAnimationFrame(anim);
    }
    this.raf = requestAnimationFrame(anim);
    this.setPosition();
    this.drawArc();
  }
}
