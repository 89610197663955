import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AnimatorService {
  animators = {};
  animInfo = {
    "Smart_S_Radar": { ang: [360, 360], rpm: 27 },
    "57mm_gun": { ang: [90, -180, 90], rpm: 10 },
    "Phalanx_CIWS": { ang: [120, -240, 120], rpm: 10 },
    "CH148": { ang: [], rpm: null },
    "RHIB_1_arm": { ang: [], rpm: null },
    "RHIB_2_arm": { ang: [], rpm: null }
  }
  dfx;
  datauxview;
  spheremodel;
  comp_target_arr;
  multipleTagStatus;
  compTargetOff;//
  private broadcaster = new Subject<any>();
  broadcastObs: Observable<any> = this.broadcaster.asObservable();
  scannerStatus='stop';
  docked=false;
  constructor() {

  }
  runAppLoop(){
    setInterval(() => {
      //this.scaleCameraTargets();
    }, 100)
  }
  getState(key){
    let animator = this.animators[key];
    if(animator){
      return animator.state;
    }
  }
  callAnimator(key,method,args){
    if(this.animators[key]){
      this.animators[key][method](args);
    }
  }
  stopAllAnim(boo=true,broadcast=false) {
    for (let m in this.animators) {
      this.animators[m].stopSubSystemAnim(boo,broadcast);
    }
  }
  stopAnim(key,boo=true,broadcast=false) {
    if(this.animators[key])
      this.animators[key].stopSubSystemAnim(boo,broadcast);
  }
  pauseAllAnim(boo=null,freeze=false) {
    for (let m in this.animators) {
      this.animators[m].pauseSubSystemAnim(boo,freeze);
    }
  }
  showHideProps(boo=null,key=null) {
    for (let m in this.animators) {
      this.animators[m].showHideProps(boo,key);
    }
  }
  pauseAnim(m, boo=null,freeze=false) {
    if(this.animators[m])
      this.animators[m].pauseSubSystemAnim(boo,freeze);
  }
  triggerPausedAnim(key=null) {
    if(key&&this.animators[key]){
      this.animators[key].triggerLastAnim();
      return
    }
    for (let m in this.animators) {
      this.animators[m].triggerLastAnim();//
    }
  }
  setTagStatus(prop, boo) {
    this[prop] = boo;
    for (let m in this.animators) {
      this.animators[m][prop] = boo;
    }
  }
  init(_datauxview, _comp_target_arr, sm = null) {
    this.datauxview = _datauxview;
    this.dfx = _datauxview.getDatascape();
    this.comp_target_arr = _comp_target_arr;
    if (sm) {
      this.spheremodel = sm;
    }
  }
  attach(elem) {
    this.dfx.attach(elem, {
      actions: {
        pickUp: [(evt, elem) => {
          if (evt.button === 0) {
            this.onClick(elem);
          }
        }]
      }
    });
    this.initAnimatorElem(elem);
  }
  onClick(elem = null, key = null) {
    if (!(elem || key)) {
      return
    }
    if (elem) {
      this.processAnimatorElem(elem);
    }
    if (key) {
      this.processAnimatorKey(key);
    }
  }
  processAnimatorKey(key) {
    if (key) {
      let elem = this.comp_target_arr[key][1];
      this.processAnimatorElem(elem);
    }
  }
  processAnimatorElem(_elem) {
    let dfx = this.dfx;
    let elem = _elem;
    let props = dfx.props(elem);
    let id = props.id;
    let a1 = id.split("comp_target_")[1];
    let pid = a1.split("_c_")[0];
    let info = this.animInfo[pid] || {};
    let animator = this.animators[pid];
    if (!animator) {
      this.animators[pid] = new Animator(pid, this.datauxview, elem, info.ang, info.rpm, this.spheremodel);
      animator = this.animators[pid];
      animator.multipleTagStatus = this.multipleTagStatus;
      animator.compTargetOff = this.compTargetOff
      animator.broadcaster = this.broadcaster;
      animator.service=this;
    }
    animator.triggerAnim();
  }
  initAnimatorElem(_elem) {
    let dfx = this.dfx;
    let elem = _elem;
    let props = dfx.props(elem);
    let id = props.id;
    let a1 = id.split("comp_target_")[1];
    let pid = a1.split("_c_")[0];
    let info = this.animInfo[pid] || {};
    let animator = this.animators[pid];
    if (!animator) {
      this.animators[pid] = new Animator(pid, this.datauxview, elem, info.ang, info.rpm, this.spheremodel);
      animator = this.animators[pid];
      animator.multipleTagStatus = this.multipleTagStatus;
      animator.compTargetOff = this.compTargetOff
      animator.broadcaster = this.broadcaster;
      animator.service=this;
    }

  }
}

export class Animator {
  targElem;
  elem;
  dfx;
  animInfo;
  id: string;
  ang: number;
  compAnimInProgress = null;
  multipleTagStatus: boolean = false;
  loopCompAnims = true;
  robjID: number = null;
  compTargetOff: boolean = false;
  broadcaster;
  boatanim: BoatAnimator;
  lastState = 'stopped';
  state = 'stopped';
  subID=null;
  service;
  constructor(_id, private datauxview, _elem, _animInfo, private rpm = 5, private spheremodel = null) {
    this.dfx = datauxview.getDatascape();
    this.targElem = _elem;
    this.elem = datauxview.getElementId(_id);
    this.animInfo = _animInfo;
    this.id = _id;
    if (this.id === 'RHIB_1_arm') {
      this.subID=1
      if (!this.boatanim) {
        this.boatanim = new BoatAnimator(this.datauxview, this.dfx, "RHIB_1", this.spheremodel)
      }

    } else if (this.id === 'RHIB_2_arm') {
      this.subID=2
      if (!this.boatanim) {
        this.boatanim = new BoatAnimator(this.datauxview, this.dfx, "RHIB_2", this.spheremodel)
      }

    }
  }
  scaleDots(args){
    if(this.boatanim){
      this.boatanim.scaleDots(args)
    }

  }
  showHideProps(show,key=null){

    if(this.state!=='stopped'||(this.state==='stopped'&&this.lastState!=='stopped')){

      if(this.id==='CH148'){
        if(key&&key==='CH148'){
          return;
        }
        this.broadcaster.next({ prop: 'showhide', value: [this.id, show], type: 'action' })
      }else if(this.id==='Smart_S_Radar'){
        if(this.service.scannerStatus!=='stop'){
          this.broadcaster.next({ prop: 'showhide', value: [this.id, show], type: 'action' })
        }
      }else if(this.id.includes("RHIB")){
        this.boatanim.showHideLinePath(show);
      }
    }
  }
  triggerLastAnim(){
    if(this.lastState==='playing'){
      this.compAnimInProgress=null;
      this.triggerAnim();
    }
  }
  triggerAnim() {
    let stopAnim = this.compAnimInProgress;
    this.stopSubSystemAnim();
    if (!stopAnim) {
      if(this.id!=="Smart_S_Radar")
        this.broadcaster.next({ prop: 'broadcast', value:{key:this.id,state:"play",sub:this.subID}, type: 'broadcast' })
      this.animateComp();
    }else{
      this.broadcaster.next({ prop: 'broadcast', value:{key:this.id,state:"stop",sub:this.subID}, type: 'broadcast' })
    }
  }

  animateComp() {
    if(this.id.includes("RHIB")&&this.service.docked){
      return;
    }
    this.state="playing";
    this.compAnimInProgress = this.id;
    this.broadcaster.next({ prop: 'playing', value: [this.id, true], type: 'updateCtrlData' })
    if (this.id === 'CH148') {
      //this.animStatus = 'playing';
      this.broadcaster.next({ prop: 'animStatus', value: "playing", type: 'vars' })
    } else if (this.id === 'RHIB_1_arm') {
      if (!this.boatanim) {
        this.boatanim = new BoatAnimator(this.datauxview, this.dfx, "RHIB_1", this.spheremodel)
      }
      if(!this.service.docked){
      this.boatanim.startBoatAnim();
      }
    } else if (this.id === 'RHIB_2_arm') {
      if (!this.boatanim) {
        this.boatanim = new BoatAnimator(this.datauxview, this.dfx, "RHIB_2", this.spheremodel)
      }
      if(!this.service.docked){
      this.boatanim.startBoatAnim();
      }
    } else {
      this.rotateObject();
    }

    if (!this.multipleTagStatus && this.targElem) {
      this.changeTargetProfile(this.targElem);
    }
  }
  rotateObject(arr = null) {
    let rotarr = arr || this.animInfo.slice();
    let ang = rotarr.shift();
    window.cancelAnimationFrame(this.robjID);
    if (ang) {
      this.rotateObjectTo(ang, rotarr)
    } else {
      if (this.loopCompAnims) {
        this.animateComp();
      } else {
        this.compAnimInProgress = null;
        this.broadcaster.next({ prop: 'playing', value: [this.id, false], type: 'updateCtrlData' })
        if (!this.multipleTagStatus) {
          if (!this.compTargetOff) {
            //this.showHideCompTargets(true);
            this.broadcaster.next({ prop: 'showHideCompTargets', value: true, type: 'func' })
          }
        }
      }
    }
  }
  rotateObjectTo(ang, rotarr) {
    let i = 0;
    let lim;
    let dir = ang / Math.abs(ang);
    let rpm = this.rpm;
    let rps = rpm / 60;
    let aps = 360 * rps;
    let dang = aps / 60;
    let inc = dir * dang;
    let dfx = this.dfx;
    let elem = this.elem;
    dfx.setElementTransition(elem, 0);
    let props = dfx.props(elem);
    let iornt = props.geometry.orientation;
    lim = iornt.y + ang;
    let dx, dy, dz;
    let animate = (timestamp) => {
      if(this.state==='paused'){
        this.robjID = window.requestAnimationFrame(animate)
        return;
      }
      dx = iornt.x + 0;
      dz = iornt.z + 0;
      dy = iornt.y + i;
      let mornt = { x: dx, y: dy, z: dz };
      i += inc
      let boo = lim < 0 ? dy < lim : dy > lim
      if (boo) {
        mornt.y = lim;
        dfx.modify(elem, { geometry: { orientation: mornt } });
        this.rotateObject(rotarr);
      } else {
        dfx.modify(elem, { geometry: { orientation: mornt } });
        this.robjID = window.requestAnimationFrame(animate)
      }

    }
    this.robjID = window.requestAnimationFrame(animate)
  }
  stopCompAnim() {
    let elem = this.elem;
    let pid = this.id;
    let dfx = this.dfx;
    if (pid == 'CH148') {
      //this.animStatus="forceStop";
      this.broadcaster.next({ prop: 'playing', value: [this.id, false], type: 'updateCtrlData' })
      this.broadcaster.next({ prop: 'animStatus', value: "forceStop", type: 'vars' })
    } else if (this.id === 'RHIB_1_arm' || this.id === 'RHIB_2_arm') {
      if (this.boatanim) {
        this.boatanim.stopBoatAnim();
      }

    } else {
      dfx.modify(elem, { geometry: { orientation: { x: 0, y: 0, z: 0 } } });
    }

  }
  pauseSubSystemAnim(boo=null,freeze=false) {
    let isFromUI=boo===null&&!freeze;
    if(this.state==='stopped'&&this.lastState==='playing'){
      this.animateComp();
      return;
    }
    if(this.state==='stopped'&&this.lastState==='paused'&&isFromUI){
      this.lastState="playing";
      this.animateComp();
      return;
    }
    if(this.lastState==='paused'&&freeze){
      if(this.id==='CH148'){
        this.broadcaster.next({ prop: 'animStatus', value: "paused", type: 'vars' })
      }
      return;
    }
    if(this.state=='paused'||this.lastState=='paused'){
      this.state="playing";

    }else if(this.state=='playing'){
      this.state='paused'
    }
    if(!freeze){
      this.lastState=this.state;
    }
    if(boo!==null){
      if(this.state!='stopped'){
        this.state=boo?"paused":"playing";
      }
    }
    if (this.id === 'CH148') {
      this.broadcaster.next({ prop: 'animStatus', value: this.state, type: 'vars' })
    } else if (this.id === 'RHIB_1_arm'||this.id === 'RHIB_2_arm') {
      if(this.boatanim){
        this.boatanim.pause(this.state);
      }
    }
  }
  stopSubSystemAnim(boo=true,broadcast=false) {
    if(this.compAnimInProgress&&!boo){
      this.lastState=this.lastState==='paused'?'paused':'playing';
    }else{
      this.lastState='stopped'
    }
    let elem = this.targElem;
    if (!this.multipleTagStatus && elem) {
      this.changeTargetProfile(elem, true);
    }
    window.cancelAnimationFrame(this.robjID);
    this.stopCompAnim();
    if(boo){
      this.compAnimInProgress = null;
    }
    this.broadcaster.next({ prop: 'playing', value: [this.id, false], type: 'updateCtrlData' });
    if(broadcast){
      this.broadcaster.next({ prop: 'broadcast', value:{key:this.id,state:"stop",sub:this.subID}, type: 'broadcast' })
    }
    this.state="stopped";
  }
  changeTargetProfile(targ, boo = false) {
    let dfx = this.dfx;
    let profile = boo ? 'target' : 'stop';
    dfx.modify(targ, { profile })
  }

}
/**
 * RHIB boat animation
 */
export class BoatAnimator {
  boat;
  arm;
  key;
  dir = 1;
  animID;
  arm_geom;
  boat_geom;
  cable
  cable_anch={x:0,y:0,z:0}
  constructor(private datauxview, private dfx, key, private spheremodel) {
    this.key = key;
    if (this.key === 'RHIB_2') {
      this.dir = -1
    }
    this.init();
  }
  init() {
    this.boat = this.datauxview.getElementId(this.key);
    this.arm = this.datauxview.getElementId(this.key + "_arm");
    this.arm_geom = this.dfx.props(this.arm).geometry;
    this.boat_geom = this.dfx.props(this.boat).geometry;
    window["boat_scope"] = this;
    this.addCable();
    this.setCableLength();
  }
  setCableLength(){
    let p = this.dfx.props(this.boat).geometry.position;
    let p0=this.boat_geom.position;
    let dh=(p.y-p0.y);
    let h=1.5-dh;
    this.dfx.setElementScale(this.cable,[1,h,1])
    let p1 = this.cable_anch;
    let py=p1.y-(h/2);
    this.dfx.modify(this.cable,{geometry:{position:{x:p1.x,y:py,z:p1.z}}})
  }
  addCable(){
    let dfx = this.dfx;
    dfx.addShape('cylinder', [{ name: 'rhib_cable', height:1,diaTop:0.04,diaBottom:0.04,tesselation:18 }])
    let opt = {
      shape: 'rhib_cable',
      id: 'm_cable',
      size: 1,
      profiles: {
        regular: {
          material: 'grey',
          transition:0
        },
        red: {
          material: 'see_me_red',
          transition:0
        },
        hidden: {
          material: 'hidden_obj',
          transition:0
        },
        transparent: {
          material: 'transparent',
          transition:0
        }
      }
    }
    let model = dfx.model(opt);
    this.cable = dfx.add({
      id: 'ecable_'+this.key,
      model,
      geometry: { position: { x: 0.15*this.dir, y:(1.25+0.75), z: 4.5 } }
    });
    this.cable_anch = { x: 0.15*this.dir, y:(1.25+0.75), z: 4.5 }
    dfx.setAsParent(this.arm,[this.cable]);
  }
  paused = false;
  pause(state){
    this.paused = state==='paused'?true:false;
  }
  stopBoatAnim() {
    this.clearLinePath();
    cancelAnimationFrame(this.animID);
    /* this.dfx.modify(this.arm, { geometry: this.arm_geom });
    this.dfx.modify(this.boat, { geometry: this.boat_geom }); */
    let p1=this.arm_geom.position;
          let p2=this.boat_geom.position;
          let o1=this.arm_geom.orientation;
          let o2=this.boat_geom.orientation;
          this.dfx.modify(this.arm, { geometry: {position:p1,orientation:o1} });
          this.dfx.modify(this.boat, { geometry: {position:p2,orientation:o2} });
          this.setCableLength();
          this.paused=false;
  }
  startBoatAnim() {
    let r = this.dir === 1 ? 90 : -90;
    let dir = this.dir;
    let ang = 0;
    let dr = 0.5;
    let arm_geom = this.dfx.props(this.arm).geometry;
    let boat_geom = this.dfx.props(this.boat).geometry;
    let c = arm_geom.position;
    let p = boat_geom.position;
    let ip = this.clone(p);
    let R1 = p.x - c.x;
    let R2 = p.z - c.z;
    let dl = 0;
    let speed = 0.1
    let state = 'lift boat';
    let frame = 0;
    let rIdx = 0;

    let anim = () => {
      if(this.paused){
        this.animID = requestAnimationFrame(anim);
        return;
      }
      if (state === 'lift boat') {
        if (dl >= 1.5) {
          state = "open arm"
          dl = 1.5
        }
        let nx = p.x;
        let ny = p.y + (dl / 2);
        let nz = p.z + dl;
        this.dfx.modify(this.boat, { geometry: { position: { x: nx, y: ny, z: nz } } })
        dl += (0.1*2)
        if (state === 'open arm') {
          boat_geom = this.dfx.props(this.boat).geometry;
          p = boat_geom.position;
          R1 = p.x - c.x;
          R2 = p.z - c.z;

        }
      }
      if (state === 'open arm') {
        if (Math.abs(ang) >= r * dir) {
          state = "drop boat";
          ang = r;
        }
        this.dfx.modify(this.arm, { geometry: { orientation: { x: 0, y: ang, z: 0 } } });
        let theta = ang * Math.PI / 180;
        let nx = R1 * Math.cos(theta) + R2 * Math.sin(theta) + c.x;
        let ny = R1 * Math.sin(theta) + R2 * Math.cos(theta) + c.z;
        this.dfx.modify(this.boat, { geometry: { orientation: { x: 0, y: ang, z: 0 }, position: { x: nx, y: p.y, z: ny } } })

        ang += (dr * dir*3);
        if (state === 'drop boat') {
          ip = this.clone(p);
        }
      }
      if (state === 'drop boat') {
        boat_geom = this.dfx.props(this.boat).geometry;
        p = boat_geom.position;
        let nx = p.x;
        let ny = p.y - speed;
        let nz = p.z;
        if (ny < 0) {
          ny = 0;
          state = "move boat";
          ang = 0;
        }
        this.dfx.modify(this.boat, { geometry: { position: { x: nx, y: ny, z: nz } } })
        if (state === 'move boat') {
          ip = { x: nx, y: ip.y, z: nz }
        }
      }
      if (state === 'move boat') {
        boat_geom = this.dfx.props(this.boat).geometry;
        p = boat_geom.position;
        let o = boat_geom.orientation.y
        let yrot = o + (ang * dir);
        if (Math.abs(yrot) >= 180) {
          state = 'rotate boat'
          yrot = 180 * dir;
        }
        let nx = p.x + (0.25 * dir);
        let ny = p.y;
        let nz = p.z;
        this.dfx.modify(this.boat, { geometry: { orientation: { x: 0, y: yrot, z: 0 }, position: { x: nx, y: ny, z: nz } } })
        ang += (dr / 2);
        if (frame % 15 === 0) {
          let pos = { x: nx, y: ny, z: nz };
          this.renderPath(pos, rIdx);
          rIdx++;
        }
        frame++;
        if (state === "rotate boat") {
          boat_geom = this.dfx.props(this.boat).geometry;
          p = boat_geom.position;
          c = { x: p.x + (40 * dir), y: p.y, z: p.z }
          R1 = p.x - c.x;
          R2 = p.z - c.z;
          ang = 0;
        }
      }
      if (state === "rotate boat") {
        boat_geom = this.dfx.props(this.boat).geometry;
        p = boat_geom.position;
        let o = boat_geom.orientation.y;
        if (ang >= 360) {
          ang = 360;
          state = "reposition boat"
        }
        let theta = ang * Math.PI / 180;
        let nx = R1 * Math.cos(theta) - R2 * Math.sin(theta) + c.x;
        let ny = dir * (R1 * Math.sin(theta) + R2 * Math.cos(theta)) + c.z;
        let yrot = o - (dr * dir)
        this.dfx.modify(this.boat, { geometry: { orientation: { x: 0, y: yrot, z: 0 }, position: { x: nx, y: p.y, z: ny } } })
        ang += dr;
        if (frame % 15 === 0) {
          let pos = { x: nx, y: p.y, z: ny };
          this.renderPath(pos, rIdx);
          rIdx++;
        }
        frame++;
        if (state === 'reposition boat') {
          ang = 0;
        }
      }
      if (state === 'reposition boat') {
        boat_geom = this.dfx.props(this.boat).geometry;
        p = boat_geom.position;
        let o = boat_geom.orientation.y
        let yrot = o - (ang * dir);

        let nx = p.x - (0.25 * dir);
        let ny = p.y;
        let nz = p.z;
        if (Math.abs(yrot) >= 270) {
          state = 'pull boat'
          yrot = 90 * dir;
          p = this.clone(ip);
          nx = p.x;
          ny = 0;
          nz = p.z;
        }
        this.dfx.modify(this.boat, { geometry: { orientation: { x: 0, y: yrot, z: 0 }, position: { x: nx, y: ny, z: nz } } })
        ang += (dr / 2);
        if (frame % 15 === 0) {
          let pos = { x: nx, y: p.y, z: ny };
          this.renderPath(pos, rIdx);
          rIdx++;
        }
        frame++;
        if (state === "pull boat") {
          ang = 0;
          boat_geom = this.dfx.props(this.boat).geometry;
          p = boat_geom.position;

        }
      }
      if (state === 'pull boat') {
        boat_geom = this.dfx.props(this.boat).geometry;
        p = boat_geom.position;
        let nx = p.x;
        let ny = p.y + speed;
        let nz = p.z;
        if (ny >= ip.y) {
          ny = ip.y;
          state = "close arm";
          ang = r;
          c = this.arm_geom.position;
          let p = this.boat_geom.position
          R1 = p.x - c.x;
          R2 = (p.z + 1.5) - c.z;
        }
        this.dfx.modify(this.boat, { geometry: { position: { x: nx, y: ny, z: nz } } })
      }
      if (state === 'close arm') {
        if (ang * dir <= 0) {
          state = "dock boat";
          ang = 0;
        }
        this.dfx.modify(this.arm, { geometry: { orientation: { x: 0, y: ang, z: 0 } } });
        let theta = ang * Math.PI / 180;
        let nx = R1 * Math.cos(theta) + R2 * Math.sin(theta) + c.x;
        let ny = R1 * Math.sin(theta) + R2 * Math.cos(theta) + c.z;
        this.dfx.modify(this.boat, { geometry: { orientation: { x: 0, y: ang, z: 0 }, position: { x: nx, y: p.y, z: ny } } })

        ang -= (dr * dir*3);
        if (state === 'dock boat') {
          boat_geom = this.dfx.props(this.boat).geometry;
          p = boat_geom.position;
          dl = 0
        }
      }
      if (state === 'dock boat') {
        if (dl >= 1.5) {
          state = "stop"
          dl = 1.5;
          //this.clearLinePath()
          //this.stopBoatAnim();
        }
        let nx = p.x;
        let ny = p.y - (dl / 2);
        let nz = p.z - dl;
        this.dfx.modify(this.boat, { geometry: { position: { x: nx, y: ny, z: nz } } })
        dl += (0.1*2)
        if (state === 'stop') {
          let p1=this.arm_geom.position;
          let p2=this.boat_geom.position;
          this.dfx.modify(this.arm, { geometry: {position:p1} });
          this.dfx.modify(this.boat, { geometry: {position:p2} });
          this.clearLinePath();
          frame = 0;
          rIdx = 0;
          dl = 0;
          arm_geom = this.dfx.props(this.arm).geometry;
          boat_geom = this.dfx.props(this.boat).geometry;
          c = arm_geom.position;
          p = boat_geom.position;
          ip = this.clone(p);
          R1 = p.x - c.x;
          R2 = p.z - c.z;
          setTimeout(() => {
            state = "lift boat"
          }, 1000)

        }
      }
      this.setCableLength();
      this.animID = requestAnimationFrame(anim);
    }
    this.animID = requestAnimationFrame(anim);

  }
  renderPath(pos, frame) {
    this.line_path_points.push(pos);
    if (frame > 0) {
      let lastpos = this.line_path_points[this.line_path_points.length - 2]
      this.drawLine(pos, lastpos);
    }
    this.drawDots(frame, pos);
  }
  line_path_points = [];
  line_paths = [];
  dot_paths = [];
  line_path_models = {};
  drawLine(p1, p2) {
    // console.log("drawLine");
    let dfx = this.dfx;
    let m = "boat_path_" + this.line_paths.length;
    if (!this.line_path_models['m_' + m]) {
      dfx.addShape('line', [{ name: m, coordinates: [[-p1.x, p1.z], [-p2.x, p2.z]], color: [0.6667, 0.6667, 0.702] }])
      let opt = {
        shape: m,
        id: 'm_' + m,
        profiles: {
          hidden: {
            material: 'custom-included',
            visible: false
          },
          regular: {
            material: 'custom-included',
            visible: true
          }
        }

      }

      this.line_path_models['m_' + m] = dfx.model(opt)
    }
    let mymodel = this.line_path_models['m_' + m]
    //setTimeout(() => {
    let el = dfx.add({
      id: m,
      model: mymodel,

      geometry: { position: { x: 0, y: -0.25, z: 0 }, size: 1 }
    });
    let mesh = dfx.getElementMesh(el);
    mesh.alwaysSelectAsActiveMesh = true
    this.line_paths.push(el);
  }
  drawDots(i, p) {
    let id = 'path_boat_' + i;
    let pelm = this.dfx.add({
      id,
      model: this.spheremodel,
      geometry: { position: { x: -p.x, y: -0.25, z: p.z }, size: 0.5 }
    });
    this.dfx.modify(pelm, { profile: "dfx_#aaaab3" });
    this.dot_paths.push(pelm);
  }
  clearLinePath() {
    // console.log("clearLinePath",this.line_paths);
    let dfx = this.dfx;
    this.line_paths.forEach((line) => {
      dfx.remove(line)
    })
    this.dot_paths.forEach((line) => {
      dfx.remove(line)
    })
    this.line_paths = [];
    this.dot_paths = [];
  }
  showHideLinePath(show=true) {
    let profile=show?'regular':'hidden'
    let dfx = this.dfx;
    this.line_paths.forEach((line) => {
      dfx.modify(line,{profile})
    })
    profile=show?'dfx_#aaaab3':'hidden'

    this.dot_paths.forEach((line) => {
      dfx.modify(line,{profile})
    })
  }
  getElemCenter(el) {
    let dfx = this.datauxview.getDatascape();
    let mesh = dfx.getElementMesh(el);
    let c = mesh.getBoundingInfo().boundingSphere.centerWorld;
    let target = { x: c.x, y: c.y, z: c.z }
    return target;
  }
  getElemRadius(el) {
    let dfx = this.datauxview.getDatascape();
    let mesh = dfx.getElementMesh(el);
    let c = mesh.getBoundingInfo().boundingSphere.radius;
    return c;
  }
  distanceVector(v1, v2) {
    var dx = v1.x - v2.x;
    var dy = v1.y - v2.y;
    var dz = v1.z - v2.z;

    return Math.sqrt(dx * dx + dy * dy + dz * dz);
  }
  bearing(x1, y1, x2, y2) {
    var dy = (y2 - y1);
    var dx = (x2 - x1);
    var brng = this.rad_toDeg(Math.atan2(dy, dx));
    return brng;
  }
  deg_toRad(deg) {
    return deg * Math.PI / 180;
  }
  rad_toDeg(rad) {
    return rad * 180 / Math.PI;
  }
  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  scaleDots(args) {
    if(!(this.dot_paths&&this.dot_paths.length)){
      return;
    }
    let dfx = this.dfx;
    let camera = dfx.getCamera();
    let ds = camera.distance.value;
    let scale = 0.5
    scale = (ds * 2 / 700);
    this.dot_paths.forEach((targ) => {
      dfx.setElementScale(targ, [scale, scale, scale])
    })
  }
}
