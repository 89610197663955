import { Component, OnInit, Input } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
@Component({
  selector: 'app-loader-screen',
  templateUrl: './loader-screen.component.html',
  styleUrls: ['./loader-screen.component.scss']
})
export class LoaderScreenComponent implements OnInit {
  broadcastSubs: any;
  @Input() loaderState: any = { state: true, process_txt: "Processing...", selectedDemo: '', event: 'showBlockLoading' };

  constructor(private communicationServ: CommunicationService) { }

  ngOnInit() {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "loader") {
          if (data.event === 'loaderState') {
            console.log("loaderState", data.data);
            this.loaderState = data.data;
          }
        }
      })
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
}
